import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { EditIcon, SearchIcon } from "@chakra-ui/icons";
import { IoMdAdd } from "react-icons/io";
import API from "../../context/API";
import { useNavigate } from "react-router-dom";

function Settings({ role }) {
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingSection, setEditingSection] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    address: "",
    email: "",
    phone: "",
    tax: 0,
    loyaltyEnabled: false,
    loyaltyRedeemRatio: "",
    loyaltySpendRatio: "",
    receiveNotifications: false,
    autoRenewSubscription: false,
  });
  const [showMerchantsAndManagers, setShowMerchantsAndManagers] =
    useState(false);
  const [merchantsAndManagers, setMerchantsAndManagers] = useState([]);
  const [filteredMerchantsAndManagers, setFilteredMerchantsAndManagers] =
    useState([]);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const endpoint =
          role === "merchant" ? "merchant/profile" : "admin/profile";
        const response = await API.get(endpoint);
        setUser(response.data.data);
        setFormData({
          name: response.data.data.name,
          username: response.data.data.username,
          address: response.data.data.address,
          email: response.data.data.email,
          phone: response.data.data.phone,
          loyaltyEnabled: response.data.data.loyaltyEnabled,
          loyaltyRedeemRatio: response.data.data.loyaltyRedeemRatio,
          loyaltySpendRatio: response.data.data.loyaltySpendRatio,
          tax: response.data.data.tax * 100,
          receiveNotifications:
            response.data.data.receiveNotifications || false,
          autoRenewSubscription:
            response.data.data.autoRenewSubscription || false,
        });
      } catch (error) {
        toast({
          title: "Error fetching user details.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchUserDetails();
  }, [toast]);
  useEffect(() => {
    if (showMerchantsAndManagers) {
      fetchMerchantsAndManagers();
    }
  }, [showMerchantsAndManagers]);

  const fetchMerchantsAndManagers = async () => {
    try {
      let response;
      if (role === "merchant") {
        response = await API.get("manager/all");
      } else if (role === "admin") {
        response = await API.get("merchant");
      } else {
        throw new Error("Invalid role");
      }
      const data = response.data.data;
      setMerchantsAndManagers(data);
      setFilteredMerchantsAndManagers(data);
    } catch (error) {
      toast({
        title: "Error fetching merchants and managers.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleEdit = (section) => {
    setIsEditing(true);
    setEditingSection(section);
  };

  const handleSave = async () => {
    try {
      const updatedData = {
        name: formData.name,
        username: formData.username,
        address: formData.address,
        email: formData.email,
        phone: formData.phone,
        loyaltyEnabled:formData.loyaltyEnabled,
        loyaltyRedeemRatio:formData.loyaltyRedeemRatio,
        loyaltySpendRatio:formData.loyaltySpendRatio,
        tax: formData.tax / 100,
        receiveNotifications: formData.receiveNotifications,
        autoRenewSubscription: formData.autoRenewSubscription,
      };

      // await API.put(`merchant/${user.id}`, updatedData);
      setUser((prev) => ({ ...prev, ...updatedData }));
      setIsEditing(false);
      setEditingSection("");
      if (user.role === "merchant") {
        await API.put(`/merchant/${user.id}`, updatedData);
      }
      toast({
        title: "User details updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating user details.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditingSection("");
    setFormData({
      name: user.name,
      username: user.username,
      address: user.address,
      email: user.email,
      phone: user.phone,
      tax: user.tax * 100,
      receiveNotifications: user.receiveNotifications,
      autoRenewSubscription: user.autoRenewSubscription,
    });
  };

  const handleMoreDetailsClick = () => {
    if (role === "merchant") {
      navigate("/merchantmanager");
    } else if (role === "admin") {
      navigate("/merchants");
    }
  };

  const handleManagerSearch = (event) => {
    const filter = event.target.value.toLowerCase();
    if (!filter) {
      setFilteredMerchantsAndManagers(merchantsAndManagers);
      return;
    }
    const filtered = merchantsAndManagers.filter(
      (item) =>
        item.name.toLowerCase().includes(filter) ||
        item.username.toLowerCase().includes(filter) ||
        item.email.toLowerCase().includes(filter)
    );
    setFilteredMerchantsAndManagers(filtered);
  };

  if (!user) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex direction="column" padding="4">
      <Box as="main" flex="1" boxShadow="sm" borderRadius="md">
        <Heading size="xl" letterSpacing={"-2"} marginBottom="6">
          Settings
        </Heading>

        {/* Profile Section */}
        <Flex
          justifyContent="space-between"
          alignItems="center"
          marginBottom="4"
        >
          <HStack>
            <Avatar
              size="xl"
              name={user.name}
              src={`https://ui-avatars.com/api/?name=${
                user?.name || user?.username
              }`}
            />
            <Box>
              <Text fontSize="2xl" fontWeight="bold">
                {user?.name || user?.username}
              </Text>
              <Text fontSize="md">{user.username}</Text>
              <Text fontSize="sm">
                {user?.address || "Location not provided"}
              </Text>
            </Box>
          </HStack>
          <IconButton
            icon={<EditIcon />}
            onClick={() => handleEdit("profile")}
          />
        </Flex>

        {/* Personal Information Section */}
        <Box
          padding="4"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="sm"
          marginBottom="4"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom="4"
          >
            <Heading size="md">Personal Information</Heading>
            <IconButton
              icon={<EditIcon />}
              onClick={() => handleEdit("personal")}
            />
          </Flex>
          <VStack spacing="4" align="start">
            {!isEditing || editingSection !== "personal" ? (
              <>
                <Text>
                  <strong>First Name:</strong>{" "}
                  {user?.name || "Name not provided"}
                </Text>
                <Text>
                  <strong>Email Address:</strong>{" "}
                  {user?.email || "Email not provided"}
                </Text>
                <Text>
                  <strong>Phone:</strong>{" "}
                  {user?.phone || "phone number not provided"}
                </Text>
              </>
            ) : (
              <>
                <FormControl>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <HStack spacing="4">
                  <Button colorScheme="teal" onClick={handleSave}>
                    Save
                  </Button>
                  <Button onClick={handleCancel}>Cancel</Button>
                </HStack>
              </>
            )}
          </VStack>
        </Box>

        {/* Address Section */}
        <Box
          padding="4"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="sm"
          marginBottom="4"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom="4"
          >
            <Heading size="md">Address</Heading>
            <IconButton
              icon={<EditIcon />}
              onClick={() => handleEdit("address")}
            />
          </Flex>
          <VStack spacing="4" align="start">
            {!isEditing || editingSection !== "address" ? (
              <>
                <Text>
                  <strong>Address:</strong>{" "}
                  {user?.address || "Location not provided"}
                </Text>
              </>
            ) : (
              <>
                <FormControl>
                  <FormLabel>Address</FormLabel>
                  <Input
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <HStack spacing="4">
                  <Button colorScheme="teal" onClick={handleSave}>
                    Save
                  </Button>
                  <Button onClick={handleCancel}>Cancel</Button>
                </HStack>
              </>
            )}
          </VStack>
        </Box>

        {/* Loyality Section */}
          <Box
            padding="4"
            borderWidth="1px"
            borderRadius="md"
            boxShadow="sm"
            marginBottom="4"
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              marginBottom="4"
            >
              <Heading size="md">Loyalty</Heading>
              <IconButton
                icon={<EditIcon />}
                onClick={() => handleEdit("loyalty")}
              />
            </Flex>

            <VStack spacing="4" align="start">
              {!isEditing || editingSection !== "loyalty" ? (
                <>
                {
                  formData.loyaltyEnabled ? (
                    <>
                    <Text>
                    <strong>Loyalty Redeem Ratio:</strong>
                    {formData?.loyaltyRedeemRatio ||
                      "Loyalty Redeem Ratio not provided"}
                  </Text>
                  <Text>
                    <strong>Loyalty Spend Ratio:</strong>
                    {formData?.loyaltySpendRatio ||
                      "Loyalty Spend Ratio not provided"}
                  </Text>
                    </>

                  ):(
                    <Switch
                    id="loyaltyEnabled"
                    name="loyaltyEnabled"
                    isChecked={formData.loyaltyEnabled}
                    isDisabled
                  />
                  )
                }
                </>
              ) : (
                <>
                  {/* If editing is enabled, show loyalty enabled toggle */}
                  <FormControl>
                    <FormLabel>Loyalty Enabled</FormLabel>
                    <Switch
                    id="loyaltyEnabled"
                    name="loyaltyEnabled"
                    isChecked={formData.loyaltyEnabled}
                    onChange={handleInputChange}
                  />
                  </FormControl>

                  {/* If loyalty is enabled, show these two fields */}
                  {formData?.loyaltyEnabled && (
                    <>
                      <FormControl>
                        <FormLabel>Loyalty Redeem Ratio</FormLabel>
                        <Input
                          name="loyaltyRedeemRatio"
                          value={formData.loyaltyRedeemRatio}
                          onChange={handleInputChange}
                          isDisabled={!isEditing} // Disable if editing is false
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Loyalty Spend Ratio</FormLabel>
                        <Input
                          name="loyaltySpendRatio"
                          value={formData.loyaltySpendRatio}
                          onChange={handleInputChange}
                          isDisabled={!isEditing} // Disable if editing is false
                        />
                      </FormControl>
                    </>
                  )}

                  {/* Save and Cancel buttons */}
                  <HStack spacing="4">
                    <Button colorScheme="teal" onClick={handleSave}>
                      Save
                    </Button>
                    <Button onClick={handleCancel}>Cancel</Button>
                  </HStack>
                </>
              )}
            </VStack>
          </Box>
        

        {/* Additional Settings Section */}
        <Box
          padding="4"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="sm"
          marginBottom="4"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom="4"
          >
            <Heading size="md">Additional Settings</Heading>
            <IconButton
              icon={<EditIcon />}
              onClick={() => handleEdit("additional")}
            />
          </Flex>
          <VStack spacing="4" align="start">
            {!isEditing || editingSection !== "additional" ? (
              <>
                <Flex align="center">
                  <FormLabel htmlFor="receiveNotifications" mb="0">
                    Receive Notifications
                  </FormLabel>
                  <Switch
                    id="receiveNotifications"
                    name="receiveNotifications"
                    isChecked={formData.receiveNotifications}
                    isDisabled
                  />
                </Flex>
                <Flex align="center">
                  <FormLabel htmlFor="autoRenewSubscription" mb="0">
                    Auto-Renew Subscription
                  </FormLabel>
                  <Switch
                    id="autoRenewSubscription"
                    name="autoRenewSubscription"
                    isChecked={formData.autoRenewSubscription}
                    isDisabled
                  />
                </Flex>
              </>
            ) : (
              <>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="receiveNotifications" mb="0">
                    Receive Notifications
                  </FormLabel>
                  <Switch
                    id="receiveNotifications"
                    name="receiveNotifications"
                    isChecked={formData.receiveNotifications}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="autoRenewSubscription" mb="0">
                    Auto-Renew Subscription
                  </FormLabel>
                  <Switch
                    id="autoRenewSubscription"
                    name="autoRenewSubscription"
                    isChecked={formData.autoRenewSubscription}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <HStack spacing="4">
                  <Button colorScheme="teal" onClick={handleSave}>
                    Save
                  </Button>
                  <Button onClick={handleCancel}>Cancel</Button>
                </HStack>
              </>
            )}
          </VStack>
        </Box>

        {/* Address Section */}
        <Box
          padding="4"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="sm"
          marginBottom="4"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom="4"
          >
            <Heading size="md">Tax</Heading>
            <IconButton icon={<EditIcon />} onClick={() => handleEdit("tax")} />
          </Flex>
          <VStack spacing="4" align="start">
            {!isEditing || editingSection !== "tax" ? (
              <>
                <Text>
                  <strong>Tax:</strong>{" "}
                  {user?.tax * 100 + "%" || "Tax not provided"}
                </Text>
              </>
            ) : (
              <>
                <FormControl>
                  <FormLabel>Tax</FormLabel>
                  <Input
                    name="tax"
                    value={formData.tax}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <HStack spacing="4">
                  <Button colorScheme="teal" onClick={handleSave}>
                    Save
                  </Button>
                  <Button onClick={handleCancel}>Cancel</Button>
                </HStack>
              </>
            )}
          </VStack>
        </Box>

        {/* Manage Merchant Managers Section */}
        <Box
          padding="4"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="sm"
          marginTop="4"
        >
          <Button
            onClick={() =>
              setShowMerchantsAndManagers(!showMerchantsAndManagers)
            }
          >
            {role === "admin"
              ? `${showMerchantsAndManagers ? "Hide" : "View/Manage"} Merchants`
              : `${showMerchantsAndManagers ? "Hide" : "View/Manage"} Managers`}
          </Button>
          <Collapse in={showMerchantsAndManagers} animateOpacity>
            <Box mt="4">
              <>
                <Flex justify="space-between" align="center" mb="4">
                  <Heading size="md">
                    {role === "admin" ? "Merchants" : "Merchant Managers"}
                  </Heading>
                  <Button
                    leftIcon={<IoMdAdd />}
                    bg="black"
                    color={"white"}
                    _hover={{
                      color: "black",
                      bg: "white",
                      borderWidth: "1px",
                      borderColor: "gray.300",
                    }}
                    variant="outline"
                    onClick={handleMoreDetailsClick}
                    size={{ base: "sm", md: "md" }}
                    display={{ base: "none", md: "flex" }} // Hide on mobile
                  >
                    {role === "admin"
                      ? "More Merchant settings"
                      : "More Managers settings"}
                  </Button>

                  <IconButton
                    onClick={handleMoreDetailsClick}
                    display={{ base: "flex", md: "none" }} // Show on mobile
                    size="sm"
                    bg="black"
                    color="white"
                    _hover={{
                      color: "black",
                      bg: "white",
                      borderWidth: "1px",
                      borderColor: "gray.300",
                    }}
                    borderRadius="full" // Rounded button for mobile
                    icon={<IoMdAdd />}
                  />
                </Flex>
                <InputGroup size="md" mb="4">
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input
                    type="search"
                    placeholder="Search..."
                    onKeyUp={handleManagerSearch}
                  />
                </InputGroup>
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Username</Th>
                        <Th>Email</Th>
                        <Th>Phone</Th>
                        <Th>Address</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredMerchantsAndManagers.map((item) => (
                        <Tr key={item.id}>
                          <Td>
                            <Link
                              color="teal.500"
                              onClick={() => handleMoreDetailsClick()}
                            >
                              {item.name}
                            </Link>
                          </Td>
                          <Td>{item.username}</Td>
                          <Td>{item.email}</Td>
                          <Td>{item.phone}</Td>
                          <Td>{item.address}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Flex>
  );
}

export default Settings;
