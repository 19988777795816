import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  Heading,
  InputGroup,
  InputRightAddon,
  Input,
  CloseButton,
  List,
  ListItem,
  Text,
  Flex,
  Wrap,
  WrapItem,
  Badge,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { CloseIcon, TimeIcon } from '@chakra-ui/icons';

const SchedulesSeletor = ({ schedules, selectedSch, onScheduleChange }) => {
  const [selectedSchedules, setSelectedSchedules] = useState(selectedSch);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [query, setQuery] = useState('');
  const [filteredSchedules, setFilteredSchedules] = useState(schedules);

  // Handle search input (if you decide to add search functionality)
  const handleSearchSchedules = (value) => {
    setQuery(value);
    setFilteredSchedules(
      schedules.filter((schedule) =>
        schedule.day_names.join(', ').toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  // Handle clearing search input
  const handleClearSearchSchedules = () => {
    setQuery('');
    setFilteredSchedules(schedules);
  };

  // Handle selecting and removing schedules from the selected list
  const handleCheckboxChange = (schedule) => {
    // Check if the schedule is already in the selectedSchedules
    const isAlreadySelected = selectedSchedules.some((sch) => sch.id === schedule.id);
  
    if (!isAlreadySelected) {
      // If not, add it to the selectedSchedules
      setSelectedSchedules((prevSelectedSchedules) => [
        ...prevSelectedSchedules,
        schedule,
      ]);
      onScheduleChange([...selectedSchedules, schedule]); // Pass the updated selectedSchedules to the parent function
    }
  
    setShowSuggestions(false); // Close the suggestions dropdown
    setQuery(""); // Clear the search query
  };
  
  // Handle removing a specific schedule
  const handleRemoveSchedule = (scheduleId) => {
    const sch = selectedSchedules.filter((sch) => sch.id !== scheduleId)
    setSelectedSchedules(sch);
    onScheduleChange(sch)
  };

  useEffect(()=>{
    
  },[selectedSch,schedules])
console.log("schedules",selectedSchedules,filteredSchedules)
  return (
    <Box>
      {/* Schedule Dropdown */}
      <FormControl mb="4">
        <Heading fontSize={"md"} mb={2}>
          Schedules
        </Heading>
        <Box position="relative">
          <InputGroup>
            <Input
              placeholder="Select schedules..."
              value={query}
              onChange={(e) => handleSearchSchedules(e.target.value)}
              onFocus={() => setShowSuggestions(true)}
              variant="filled"
              borderRadius="full"
              mb={4}
            />
            {query && (
              <InputRightAddon>
                <CloseButton onClick={handleClearSearchSchedules} />
              </InputRightAddon>
            )}
          </InputGroup>

          {showSuggestions && filteredSchedules.length > 0 && (
            <Box
              position="absolute"
              bg="white"
              border="1px solid"
              borderColor="gray.300"
              borderRadius="md"
              mt={2}
              width="100%"
              zIndex="1"
              maxHeight="150px"
              overflowY="auto"
            >
              <List spacing={1}>
                {filteredSchedules.map((schedule) => (
                  <ListItem
                    key={schedule.id}
                    p={2}
                    cursor="pointer"
                    _hover={{ bg: 'gray.200' }}
                    onClick={() => handleCheckboxChange(schedule)}
                  >
                    <Flex justify="space-between" align="center">
                      <Box>
                        <Text fontSize={12}>{schedule.day_names.join(", ")}</Text>
                        <Text display="flex" alignItems="center" mb={2} fontSize={12}>
                            <Icon as={TimeIcon} mr={2} />
                            {schedule.start_time} - {schedule.end_time}
                        </Text>
                      </Box>
                      
                      {selectedSchedules.some((sch)=> sch.id === schedule.id) && (
                        <Text color="green.500">Selected</Text>
                      )}
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
      </FormControl>

      {/* Selected Schedules */}
      <Box p={4} borderWidth={"1px"} borderColor={"#d1d1d1"} borderRadius={"lg"} mb={4}>
        <Text>Selected Schedules:</Text>
        {selectedSchedules.length === 0 ? (
          <Text color="gray.500">No schedules selected.</Text>
        ) : (
          <Wrap spacing={2} mt={2}>
            {selectedSchedules
              .map((schedule) => (
                <WrapItem key={schedule.id}>
                  <Badge
                    display="flex"
                    alignItems="center"
                    p={2}
                    borderRadius="full"
                    variant="solid"
                    colorScheme="blue"
                  >
                    {schedule.day_names.join(', ')}
                    <IconButton
                      icon={<CloseIcon />}
                      size="xs"
                      ml={2}
                      onClick={() => handleRemoveSchedule(schedule.id)}
                      variant="unstyled"
                      aria-label="Remove Schedule"
                      _hover={{ color: "red.500" }}
                    />
                  </Badge>
                </WrapItem>
              ))}
          </Wrap>
        )}
      </Box>
    </Box>
  );
};

export default SchedulesSeletor;
