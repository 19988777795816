import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Input,
  PinInput,
  PinInputField,
  VStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import API from "../context/API";

const OtpPhoneInput = ({ setIsLoggedIn, phone, role }) => {
  const [otp, setOtp] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [otpRequestCount, setOtpRequestCount] = useState(0);
  const toast = useToast();

  const MAX_OTP_REQUESTS = 5;
  const RESEND_COOLDOWN = 300; // 5 minutes in seconds
  const RESET_TIME = 3600; // 1 hour in seconds

  useEffect(() => {
    // On initial render, check if there's an existing cooldown
    const storedCooldown = localStorage.getItem("resendCooldown");
    const storedRequestCount = localStorage.getItem("otpRequestCount");

    if (storedCooldown && storedRequestCount) {
      const remainingTime = Math.max(0, RESEND_COOLDOWN - (Date.now() - storedCooldown) / 1000);
      setOtpRequestCount(parseInt(storedRequestCount, 10));
      if (remainingTime > 0) {
        setResendTimer(remainingTime);
        setIsResendDisabled(true);
      }
    }
  }, []);

  useEffect(() => {
    // Handle resend timer countdown
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            setIsResendDisabled(false);
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendTimer]);

  useEffect(() => {
    // Reset OTP request count every hour
    const resetInterval = setInterval(() => {
      setOtpRequestCount(0);
      localStorage.removeItem("otpRequestCount");
    }, RESET_TIME * 1000);

    return () => clearInterval(resetInterval);
  }, []);

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const handleVerify = async () => {
    try {
      if (otp.length < 6) {
        toast({
          title: "Invalid OTP",
          description: "Please enter a 6-digit OTP.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const res = await API.post(`/merchant/verify-otp`, {
        phone,
        otp,
      });

      if (res.data.status === 200) {
        toast({
          title: "Verification Successful",
          description: "Your phone number and OTP have been verified.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          setIsLoggedIn(true);
          // localStorage.setItem("access_token", res.data.data.jwt);
          // localStorage.setItem("user", JSON.stringify(res.data.data[role]));
          // window.location.href = `/dashboard?access_token=${res.data.data.jwt}&role=${role}`;
          window.location.href = `https://dashboard.kiwikrave.com/dashboard?access_token=${res.data.data.jwt}&role=${role}`;
        }, 1500);
        setOtpRequestCount(0);
        localStorage.removeItem("otpRequestCount");
      } else {
        toast({
          title: "Wrong OTP",
          description: "The OTP you entered is incorrect. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log("Error verifying OTP:", error);
      toast({
        title: "Verification Failed",
        description: "An error occurred while verifying the OTP. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleResendOtp = async () => {
    if (otpRequestCount >= MAX_OTP_REQUESTS) {
      toast({
        title: "Too Many Requests",
        description: `You have reached the maximum of ${MAX_OTP_REQUESTS} requests. Please wait 5 minutes.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setResendTimer(RESEND_COOLDOWN);
      setIsResendDisabled(true);
      localStorage.setItem("resendCooldown", Date.now());
      return;
    }

    try {
      await API.post(`/merchant/resend-otp`, { phone });
      setOtpRequestCount((prev) => {
        const newCount = prev + 1;
        localStorage.setItem("otpRequestCount", newCount);
        return newCount;
      });
      setResendTimer(RESEND_COOLDOWN);
      setIsResendDisabled(true);
      localStorage.setItem("resendCooldown", Date.now());
      toast({
        title: "OTP Resent",
        description: "A new OTP has been sent to your phone number.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error resending OTP:", error);
      toast({
        title: "Resend Failed",
        description: "An error occurred while resending the OTP. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      p={6}
      maxWidth="100%"
      mx="auto"
      boxShadow="lg"
      borderRadius="md"
      bg="white"
      display="flex"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        p={6}
        maxWidth="400px"
        mx="auto"
        mt={8}
        boxShadow="lg"
        borderRadius="md"
        bg="white"
        maxH="400px"
        height="400px"
        textAlign="center"
      >
        <VStack spacing={6} align="stretch">
          <Text fontSize="2xl" fontWeight="bold" color="blue.600">
            Verify Your Phone Number
          </Text>

          <Input
            value={`${"*".repeat(phone?.length - 4)}${phone?.slice(-4)}`}
            readOnly
            focusBorderColor="blue.500"
            variant="outline"
            size="lg"
            borderRadius="md"
            textAlign="center"
          />

          <HStack justifyContent="center">
            <PinInput
              onChange={handleOtpChange}
              value={otp || ""}
              focusBorderColor="blue.500"
              size="lg"
            >
              {Array.from({ length: 6 }).map((_, index) => (
                <PinInputField key={index} />
              ))}
            </PinInput>
          </HStack>

          <Button
            colorScheme="blue"
            variant="link"
            onClick={handleResendOtp}
            isDisabled={isResendDisabled}
          >
            Resend OTP {isResendDisabled ? `in ${Math.ceil(resendTimer)}s` : ""}
          </Button>

          <Button colorScheme="blue" onClick={handleVerify} isFullWidth size="lg">
            Verify
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default OtpPhoneInput;
