import React from "react";
import { Box, Button, Heading, Text, VStack, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FiHome } from "react-icons/fi"; // For a home icon

const NotFoundPage = () => {
  // Color settings
  const bgColor = useColorModeValue("gray.100", "gray.800"); // Light or Dark background
  const textColor = useColorModeValue("gray.800", "white");

  return (
    <Box
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg={bgColor}
      flexDirection="column"
    >
      {/* Container for main content */}
      <VStack spacing={4} textAlign="center" color={textColor}>
        {/* Heading */}
        <Heading as="h1" size="4xl" fontWeight="bold">
          404
        </Heading>
        {/* Subheading */}
        <Text fontSize="2xl">
          Oops! The page you are looking for does not exist.
        </Text>
       

        {/* Button to redirect back to home */}
        <Link to="/dashboard">
          <Button
            leftIcon={<FiHome />}
            colorScheme="teal"
            size={useBreakpointValue({ base: "md", md: "lg" })}
            variant="solid"
            mt={6}
          >
            Go Back Home
          </Button>
        </Link>
      </VStack>
    </Box>
  );
};

export default NotFoundPage;
