import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Spinner,
  Table,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Tbody,
  VStack,
  Switch,
  HStack,
  Collapse,
  useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon, EditIcon } from "@chakra-ui/icons";
import API from "../../context/API";
import CustomersDropDown from "./CutomersDropDown";

const Loyalty = () => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    loyaltyEnabled: false,
    loyaltyRedeemRatio: "",
    loyaltySpendRatio: "",
    minSpentForLoyalty: "",
    minSpentForRedemption: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const toast = useToast();
  const [customersLoyalties, setCutomersLoyalties] = useState([]);
  const [merchantId, setMerchantId] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const fetchCustomerLoyalties = async () => {
    try {
      setLoading(true);
      const response = await API.get("/merchant/loyalty/users", {
        params: { merchantId: merchantId },
      });
      setCutomersLoyalties(response.data.data || []);
    } catch (error) {
      console.error("Error fetching stores:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleCollapse = (id) => {
    setCutomersLoyalties((prevData) =>
      prevData.map((loyalty) =>
        loyalty.id === id
          ? { ...loyalty, isOpen: !loyalty.isOpen }
          : { ...loyalty, isOpen: false }
      )
    );
  };
  const fetchUserDetails = async () => {
    console.log("user", user);
    try {
      const endpoint =
        user.role === "merchant" ? "merchant/profile" : "admin/profile";
      const response = await API.get(endpoint);
      setFormData({
        loyaltyEnabled: response.data.data.loyaltyEnabled,
        loyaltyRedeemRatio: response.data.data.loyaltyRedeemRatio,
        loyaltySpendRatio: response.data.data.loyaltySpendRatio,
        minSpentForLoyalty: response.data.data.minSpentForLoyalty,
        minSpentForRedemption: response.data.data.minSpentForRedemption,
      });
      setLoading(false);
    } catch (error) {
      toast({
        title: "Error fetching user details.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleEdit = (section) => {
    setIsEditing(true);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);
  useEffect(() => {
    fetchCustomerLoyalties();
  }, [merchantId]);

  const handleSave = async () => {
    try {
      const updatedData = {
        loyaltyEnabled: formData.loyaltyEnabled,
        loyaltyRedeemRatio: formData.loyaltyRedeemRatio,
        loyaltySpendRatio: formData.loyaltySpendRatio,
        minSpentForLoyalty: formData.minSpentForLoyalty,
        minSpentForRedemption: formData.minSpentForRedemption,
      };
      if (user.role === "merchant") {
        await API.put(`/merchant/${user.id}`, updatedData);
      }
      setIsEditing(false);
      toast({
        title: "User details updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {}
  };
  const handleCancel = () => {
    setIsEditing(false);
  };

  const bgColor = useColorModeValue("white", "gray.800");

  return (
    <Box my={"4"}>
      <Flex justify="space-between" align="center" marginBottom="4">
        <Box>
          <Heading size={"xl"} letterSpacing={-2}>
            Loyalty
          </Heading>
          {/* <Text fontSize={"sm"}>
            List of all Loyalty, you can add new ones, edit and/or delete older
            ones.
          </Text> */}
        </Box>
      </Flex>
      {/* Loyality Section */}
      {user.role === "merchant" && (
        <Box
          padding="4"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="sm"
          marginBottom="4"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom="4"
          >
            <Heading size="md">Loyalty</Heading>
            <IconButton
              icon={<EditIcon />}
              onClick={() => handleEdit("loyalty")}
            />
          </Flex>

          <VStack spacing="4" align="start">
            {!isEditing ? (
              <>
                {formData.loyaltyEnabled ? (
                  <>
                    <Text>
                      <strong>Loyalty Redeem Ratio:</strong>
                      {formData?.loyaltyRedeemRatio ||
                        "Loyalty Redeem Ratio not provided"}
                    </Text>
                    <Text>
                      <strong>Loyalty Spend Ratio:</strong>
                      {formData?.loyaltySpendRatio ||
                        "Loyalty Spend Ratio not provided"}
                    </Text>
                    <Text>
                      <strong>Minimum Spent For Loyalty:</strong>
                      {formData?.minSpentForLoyalty ||
                        "Minimum Spent For Loyalty not provided"}
                    </Text>
                    <Text>
                      <strong>Minimum Spent For Redemption:</strong>
                      {formData?.minSpentForRedemption ||
                        "Minimum Spent For Redemption not provided"}
                    </Text>
                  </>
                ) : (
                  <Switch
                    id="loyaltyEnabled"
                    name="loyaltyEnabled"
                    isChecked={formData.loyaltyEnabled}
                    isDisabled
                  />
                )}
              </>
            ) : (
              <>
                {/* If editing is enabled, show loyalty enabled toggle */}
                <FormControl>
                  <FormLabel>Loyalty Enabled</FormLabel>
                  <Switch
                    id="loyaltyEnabled"
                    name="loyaltyEnabled"
                    isChecked={formData.loyaltyEnabled}
                    onChange={handleInputChange}
                  />
                </FormControl>

                {/* If loyalty is enabled, show these two fields */}
                {formData?.loyaltyEnabled && (
                  <HStack width={"100%"}>
                    <FormControl>
                      <FormLabel>Loyalty Redeem Ratio</FormLabel>
                      <Input
                        name="loyaltyRedeemRatio"
                        value={formData.loyaltyRedeemRatio}
                        onChange={handleInputChange}
                        isDisabled={!isEditing} // Disable if editing is false
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Loyalty Spend Ratio</FormLabel>
                      <Input
                        name="loyaltySpendRatio"
                        value={formData.loyaltySpendRatio}
                        onChange={handleInputChange}
                        isDisabled={!isEditing} // Disable if editing is false
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Minimum Spent For Loyalty</FormLabel>
                      <Input
                        name="minSpentForLoyalty"
                        value={formData.minSpentForLoyalty}
                        onChange={handleInputChange}
                        isDisabled={!isEditing} // Disable if editing is false
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Minimum Spent For Redemption</FormLabel>
                      <Input
                        name="minSpentForRedemption"
                        value={formData.minSpentForRedemption}
                        onChange={handleInputChange}
                        isDisabled={!isEditing} // Disable if editing is false
                      />
                    </FormControl>
                  </HStack>
                )}

                {/* Save and Cancel buttons */}
                <HStack spacing="4">
                  <Button colorScheme="teal" onClick={handleSave}>
                    Save
                  </Button>
                  <Button onClick={handleCancel}>Cancel</Button>
                </HStack>
              </>
            )}
          </VStack>
        </Box>
      )}

      <Box p={4} bg={bgColor} borderRadius="md" overflowX="auto">
        {
          user.role === "admin" && <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
          <Box display={"flex"}>
            <CustomersDropDown
              onOptionSelect={(option) => {
                setMerchantId(option);
              }}
            />
          </Box>
        </Box>
        }
        
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <Spinner size="xl" />
          </Box>
        ) : (
          <CustomersLoyaltyTable
            customersLoyalties={customersLoyalties}
            handleToggleCollapse={handleToggleCollapse}
          />
        )}
      </Box>
    </Box>
  );
};
const CustomersLoyaltyTable = ({
  customersLoyalties,
  handleToggleCollapse,
}) => {
  const getPhoneBlurred = (phone) => {
    if (phone.length > 4) {
      return phone.slice(0, -4);
    } else {
      return phone.slice(0, -2);
    }
  };
  const getPhoneUnBlurred = (phone) => {
    if (phone.length > 4) {
      return phone.slice(-4);
    } else {
      return phone.slice(-2);
    }
  };
  const formatDateAndTime = (isoString) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString("en-GB");
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    return { formattedDate, formattedTime };
  };

  return (
    <Box
      overflowX="auto" // Allows horizontal scrolling on smaller screens
      borderRadius="md"
      mt="4"
      p="4"
    >
      <Table
        variant="simple"
        size="sm" // Ensures compact table size for better visibility
        minWidth="600px" // Minimum width to maintain structure
        minHeight={"400px"}
        sx={{
          "@media (max-width: 1440px)": {
            fontSize: "sm",
          },
          "@media (max-width: 1024px)": {
            fontSize: "xs",
          },
          "@media (max-width: 768px)": {
            fontSize: "xs",
          },
        }}
      >
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Phone</Th>
            <Th>Points</Th>
            <Th>Total Spent</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {customersLoyalties.map((loyalty) => (
            <React.Fragment key={loyalty.id}>
              <Tr>
                <Td>{loyalty.user.name}</Td>
                <Td>
                  <Box display={"flex"}>
                    <Text
                      display={"flex"}
                      as="span"
                      filter="blur(3px)"
                      whiteSpace={"nowrap"}
                    >
                      {getPhoneBlurred(loyalty.user.phone)}
                    </Text>
                    {getPhoneUnBlurred(loyalty.user.phone)}
                  </Box>
                </Td>
                <Td>{parseFloat(loyalty.points).toFixed(2)}</Td>
                <Td>{loyalty.totalSpent}</Td>
                <Td>
                  <Button
                    variant="link"
                    onClick={() => handleToggleCollapse(loyalty.id)}
                    rightIcon={
                      loyalty.isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
                    }
                  >
                    {loyalty.isOpen ? "Hide Details" : "View Details"}
                  </Button>
                </Td>
              </Tr>

              <Tr>
                <Td colSpan={5} p="0">
                  <Collapse in={loyalty.isOpen}>
                    <Box p="4" borderWidth="1px" borderRadius="md" mb="4">
                      <Heading size="md" mb="4">
                        Transactions
                      </Heading>
                      <VStack align="start" spacing="4">
                        <Table>
                          <Thead>
                            <Tr>
                              <Th>Points Earned</Th>
                              <Th>Points Redeemed</Th>
                              <Th>Transaction Amount</Th>
                              <Th>Date&Time</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <React.Fragment key={loyalty.id}>
                              {loyalty?.loyaltyPoints[0]?.transactions?.map(
                                (transaction) => (
                                  <Tr>
                                    <Td>{transaction.pointsEarned}</Td>
                                    <Td>{transaction.pointsRedeemed}</Td>
                                    <Td>{transaction.transactionAmount}</Td>
                                    <Td>
                                      {
                                        formatDateAndTime(transaction.date)
                                          .formattedDate
                                      }{" "}
                                      {
                                        formatDateAndTime(transaction.date)
                                          .formattedTime
                                      }
                                    </Td>
                                  </Tr>
                                )
                              )}
                            </React.Fragment>
                          </Tbody>
                        </Table>
                      </VStack>
                    </Box>
                  </Collapse>
                </Td>
              </Tr>
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
export default Loyalty;
