import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Heading,
  Spinner,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import API from "../../context/API";
import DateRangePicker from "../Analytics/DateRangePicker";
import moment from "moment";
import Pagination from "../Common/Pagination/Pagination";
import DateSelector from "../Analytics/DateSelector";


const addCommasInPrices=(value)=>{
  return parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState("pending");
  const { colorMode } = useColorMode();
  const [currentpage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalpages] = useState(1);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: moment("2000-01-01"), // Start date is 1st January 2000
    endDate: moment(), // End date is the current date
  });
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  useEffect(() => {
    fetchOrders(selectedTab);
  }, [selectedTab, selectedDateRange,currentpage,limit,selectedFilter]);

  const fetchOrders = async (status) => {
    setLoading(true);
    try {
      let allOrders = [];
      const token = localStorage.getItem("access_token");
      const params1 = {
        type: "history",
        startDate: selectedDateRange.startDate,
        endDate: selectedDateRange.endDate,
        page: currentpage,
        limit: limit,
      }
      const params2 = {
        type: "history",
        filter:selectedFilter,
        page: currentpage,
        limit: limit,
      }
      if (status === "history") {
        const response = await API.get(`/orders`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params:isFilter?params2:params1,
        });
        if (response.data.success) {
          // If the response is an object with an "orders" array, extract the array
          allOrders = response.data.data.orders || [];
          setTotalpages(response.data.data.totalPages)
        }
      } else {
        const response = await API.get(`/orders?type=${status}`);
        if (response.data.success) {
          allOrders = response.data.data;
        }
      }

      setOrders(allOrders);
    } catch (error) {
      setError("An error occurred while fetching orders.");
      setOrders([]); // Set to empty array on error
    } finally {
      setLoading(false);
    }
  };

  const handleToggleCollapse = (id) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === id
          ? { ...order, isOpen: !order.isOpen }
          : { ...order, isOpen: false }
      )
    );
  };
  const handleSelection = (selection) => {
    setSelectedFilter(selection);
    setIsFilter(true);
  };
  return (
    <Box padding="4">
      <Heading size="xl" letterSpacing="-2" marginBottom="4">
        Orders
      </Heading>
      <Tabs
        variant="enclosed"
        onChange={(index) =>
          setSelectedTab(["pending", "completed", "ready", "history"][index])
        }
      >
        <TabList>
          <Tab>Pending</Tab>
          <Tab>Completed</Tab>
          <Tab>Ready</Tab>
          <Tab>History</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {loading ? (
              <Box textAlign="center" mt="4">
                <Spinner size="xl" />
              </Box>
            ) : orders.length === 0 ? (
              <Text mt="4">No pending orders found.</Text>
            ) : (
              <OrderTable
                orders={orders}
                onToggleCollapse={handleToggleCollapse}
                colorMode={colorMode}
              />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Box textAlign="center" mt="4">
                <Spinner size="xl" />
              </Box>
            ) : orders.length === 0 ? (
              <Text mt="4">No completed orders found.</Text>
            ) : (
              <OrderTable
                orders={orders}
                onToggleCollapse={handleToggleCollapse}
                colorMode={colorMode}
              />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Box textAlign="center" mt="4">
                <Spinner size="xl" />
              </Box>
            ) : orders.length === 0 ? (
              <Text mt="4">No ready orders found.</Text>
            ) : (
              <OrderTable
                orders={orders}
                onToggleCollapse={handleToggleCollapse}
                colorMode={colorMode}
              />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Box textAlign="center" mt="4">
                <Spinner size="xl" />
              </Box>
            ) : orders.length === 0 ? (
              <Text mt="4">No orders found.</Text>
            ) : (
              <>
                <Box display={"flex"} gap={2}>
                  <DateRangePicker
                    onDateRangeChange={(date) => setSelectedDateRange(date)}
                  />
                  <Box backgroundColor={"white"}>
                    <DateSelector onDateSelect={handleSelection} />
                  </Box>
                </Box>

                <OrderTable
                  orders={orders}
                  onToggleCollapse={handleToggleCollapse}
                  colorMode={colorMode}
                  showStatus
                />
                <Pagination
                  currentPage={currentpage}
                  itemsPerPage={limit}
                  totalPages={totalPages}
                  onItemsPerPageChange={(lim) => setLimit(lim)}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
const user = localStorage.getItem("user");

let parsedUser;
if (user) {
  try {
    parsedUser = JSON.parse(user);
  } catch (e) {
    console.error("Failed to parse user from localStorage", e);
    parsedUser = null; 
  }
} else {
  parsedUser = null;  
}
const OrderTable = ({
  orders,
  onToggleCollapse,
  showStatus = false,
  colorMode,
}) => {
  const getPhoneBlurred = (phone) => {
    if (phone.length > 4) {
      return phone.slice(0, -4);
    } else {
      return phone.slice(0, -2);
    }
  };
  const getPhoneUnBlurred = (phone) => {
    if (phone.length > 4) {
      return phone.slice(-4);
    } else {
      return phone.slice(-2);
    }
  };
  const formatDateAndTime = (isoString) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString("en-GB");
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  
    return { formattedDate, formattedTime };
  };

  return (
    <Box
      overflowX="auto" // Allows horizontal scrolling on smaller screens
      borderRadius="md"
      mt="4"
      p="4"
    >
      <Table
        variant="simple"
        size="sm" // Ensures compact table size for better visibility
        minWidth="600px" // Minimum width to maintain structure
        sx={{
          "@media (max-width: 1440px)": {
            fontSize: "sm",
          },
          "@media (max-width: 1024px)": {
            fontSize: "xs",
          },
          "@media (max-width: 768px)": {
            fontSize: "xs",
          },
        }}
      >
        <Thead>
          <Tr>
            <Th>Order ID</Th>
            <Th>User Name</Th>
            <Th>Date and Time</Th>
            <Th>User Phone</Th>
            {showStatus && <Th>Status</Th>}
            <Th>Total Price</Th>
            <Th>Sub Total</Th>
            <Th>Total Tax</Th>
            <Th>Tip</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders.map((order) => (
            <React.Fragment key={order.id}>
              <Tr>
                <Td>{order.id}</Td>
                <Td>{order.user_name}</Td>
                <Td>{formatDateAndTime(order.date).formattedDate}{" "}{formatDateAndTime(order.date).formattedTime}</Td>
                <Td>
                  <Box display={"flex"}>
                    <Text
                      display={"flex"}
                      as="span"
                      filter="blur(3px)"
                      whiteSpace={"nowrap"}
                    >
                      {getPhoneBlurred(order.user_phone)}
                    </Text>
                    {getPhoneUnBlurred(order.user_phone)}
                  </Box>
                </Td>
                {showStatus && <Td>{order.status}</Td>}
                <Td>
                  $
                  {order?.total_price != null && !isNaN(order.total_price)
                    ? addCommasInPrices(parseFloat(order.total_price).toFixed(2))
                    : "0.00"}
                </Td>
                <Td>
                  $
                  {order?.total_price != null &&
                  order?.tip != null &&
                  order?.total_price != null &&
                  !isNaN(parsedUser?.tax) &&
                  !isNaN(order.tip) &&
                  !isNaN(parsedUser?.tax)
                    ? addCommasInPrices(parseFloat(
                        order.total_price - 
                          (parseFloat(order?.tip) +
                            parseFloat(order.total_price * parsedUser?.tax))
                      ).toFixed(2))
                    : "0.00"}
                </Td>
                <Td>
                  $
                  {order?.total_price != null &&
                  !isNaN(order.total_price) &&
                  parsedUser?.tax != null &&
                  !isNaN(parsedUser.tax)
                    ? addCommasInPrices(parseFloat(order.total_price * parsedUser?.tax).toFixed(
                        2
                      ))
                    : "0.00"}
                </Td>
                <Td>
                  $
                  {order?.tip != null && !isNaN(order?.tip)
                    ? addCommasInPrices(parseFloat(order.tip).toFixed(2))
                    : "0.00"}
                </Td>
                <Td>
                  <Button
                    variant="link"
                    onClick={() => onToggleCollapse(order.id)}
                    rightIcon={
                      order.isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
                    }
                  >
                    {order.isOpen ? "Hide Details" : "View Details"}
                  </Button>
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={showStatus ? 6 : 5} p="0">
                  <Collapse in={order.isOpen}>
                    <Box p="4" borderWidth="1px" borderRadius="md" mb="4">
                      <Heading size="md" mb="4">
                        Order Items
                      </Heading>
                      <VStack align="start" spacing="4">
                        {order.items.map((item) => (
                          <Box
                            key={item.id}
                            padding="2"
                            borderWidth="1px"
                            borderRadius="md"
                            w="100%"
                            bg={colorMode === "light" ? "gray.50" : "gray.700"}
                          >
                            <Text fontWeight="bold">{item.item.item}</Text>
                            <Text>Quantity: {item.quantity}</Text>
                            <Text>
                              Price: $
                              {item?.itemPrice != null && !isNaN(item.itemPrice)
                                ? addCommasInPrices(parseFloat(item.itemPrice).toFixed(2))
                                : "0.00"}
                            </Text>

                            {item.orderItemCustomizations.map(
                              (customization) => (
                                <Box
                                  key={customization.id}
                                  mt="2"
                                  ml="4"
                                  pl="4"
                                  borderLeft="2px solid gray"
                                >
                                  <Text
                                    fontSize="sm"
                                    color={
                                      colorMode === "light"
                                        ? "gray.600"
                                        : "gray.200"
                                    }
                                  >
                                    {customization.customization.customization}:
                                    {customization.item.item} (+$
                                    {customization?.itemPrice != null &&
                                    !isNaN(customization.itemPrice)
                                      ? addCommasInPrices(parseFloat(
                                          customization.itemPrice
                                        ).toFixed(2))
                                      : "0.00"}
                                    )
                                  </Text>
                                </Box>
                              )
                            )}
                          </Box>
                        ))}
                      </VStack>
                    </Box>
                  </Collapse>
                </Td>
              </Tr>
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Orders;
