import React, {useEffect, useState} from "react";
import {
  Box,
  Collapse,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Icon,
  IconButton,
  Link,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToken,
  VStack,
} from "@chakra-ui/react";
import {HamburgerIcon} from "@chakra-ui/icons";
import {FaChevronDown, FaChevronUp, FaCog,} from "react-icons/fa";

import {Link as RouterLink, useLocation} from "react-router-dom";
import {motion} from "framer-motion";
import IconMapping from "./IconMapping.jsx";
import API from "../../context/API";

const roleMapping = {
  manager: "merchant_manager",
  staff: "merchant_manager_staff",
  merchant_manager: "manager",
  merchant_manager_staff: "staff",
};

const convertRoleToApi = (role) => roleMapping[role] || role;
const convertRoleFromApi = (role) => roleMapping[role] || role;

const sidebarLinks = [
  { icon: "FaHome", label: "Home", to: "/dashboard" },
  { icon: "FaUsers", label: "Merchants", to: "/merchants" },
  {
    icon: "FaMobile",
    label: "Devices",
    to: "#",
    children: [
      { label: "Device", to: "/devices", icon: "FaMobile" },
      { label: "Device Types", to: "/device-types", icon: "FaMobile" },
    ],
  },
  { icon: "GrAnalytics", label: "Analytics", to: "/analytics" },
  { icon: "HiDocumentReport", label: "Reports", to: "/reports" },
  { icon: "MdPayment", label: "Payments", to: "/payments" },
  { icon: "FaCog", label: "Settings", to: "/settings" },
  { icon: "BiCategoryAlt", label: "Categories", to: "/categories" },
  { icon: "FiActivity", label: "Action Logs", to: "/logs" },
  { icon: "FiAward", label: "Loyalty", to: "/loyalty" },
  { icon: "FiBarChart", label: "Sales Analytics", to: "/sales-analytics" },

  // {
  //     icon: "MdMenuBook",
  //     label: "Menu",
  //     to: "#",
  //     children: [
  //         {icon: "MdMenuBook", label: "Items", to: "/items"},
  //         {icon: "CgColorPicker", label: "Customizations", to: "/customizations"},
  //     ],
  // },
];

const sidebarLinksForStaff = [
  { icon: "FaHome", label: "Home", to: "/dashboard" },
  { icon: "GrAnalytics", label: "Analytics", to: "/analytics" },
  { icon: "HiDocumentReport", label: "Reports", to: "/reports" },
  { icon: "MdMenuBook", label: "Items", to: "/items" },
  { icon: "CgColorPicker", label: "Customizations", to: "/customizations" },
  { icon: "TfiStatsUp", label: "Sales", to: "/sales" },
];

const MotionBox = motion(Box);

const SidebarLink = ({
  icon,
  label,
  to,
  onClose,
  children,
  size = "md",
  colorMode,
  glassEffect,
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleToggle = (e) => {
    if (children) {
      e.preventDefault();
      setIsOpen(!isOpen);
    } else {
      if (onClose && !children) {
        onClose();
      }
    }
  };
  const activeBg = useToken(
    "colors",
    colorMode === "dark" ? "gray.700" : "gray.200"
  );
  const hoverBg = useToken(
    "colors",
    colorMode === "dark" ? "gray.600" : "gray.100"
  );
  const inactiveBg = "transparent";

  // Normalize the 'to' value to ensure it starts with a '/'
  const adjustedTo = to.startsWith("/") ? to : `/${to}`;

  // Adjusted active link determination
  const isLinkOrChildActive =
    location.pathname.startsWith(adjustedTo) ||
    (children &&
      children.some((child) => {
        const childAdjustedTo = child.to.startsWith("/")
          ? child.to
          : `/${child.to}`;
        return location.pathname.startsWith(childAdjustedTo);
      }));

  const sidebarIconSize = useBreakpointValue({ base: "3", md: "4" });

  const textColor = colorMode === "dark" ? "white" : "gray.600";
  const iconColor = colorMode === "dark" ? "white" : "gray.600";
  const bg = isLinkOrChildActive ? activeBg : isHovering ? hoverBg : inactiveBg;

  const iconSizes = {
    sm: "3",
    md: "4",
    lg: "5",
  };

  const fontSizes = {
    sm: "xs",
    md: "sm",
    lg: "md",
  };

  const currentIconSize = iconSizes[size];
  const currentFontSize = fontSizes[size];
  const hoverTextColor = colorMode === "dark" ? "gray.800" : "gray.800";

  const linkStyles = {
    _hover: {
      color: hoverTextColor,
      bg: hoverBg,
    },
    _activeLink: {
      color: colorMode === "dark" ? "blue.300" : "blue.600",
      bg: colorMode === "dark" ? "rgba(255, 255, 255, 0.2)" : "gray.200",
    },
  };

  const IconComponent = IconMapping[icon] || FaCog; // Default icon if not found

  return (
    <>
      <Link
        as={RouterLink}
        to={children ? "#" : to}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={handleToggle}
        _hover={{ textDecoration: "none", ...linkStyles._hover }}
      >
        <MotionBox
          whileHover={{ scale: 1.1 }}
          align="center"
          p={2}
          bg={bg}
          borderRadius="md"
          display="flex"
          alignItems="center"
          {...(isLinkOrChildActive && linkStyles._activeLink)}
          justifyContent="start"
          cursor="pointer"
          color={isLinkOrChildActive || isHovering ? textColor : iconColor}
        >
          <Icon as={IconComponent} boxSize={currentIconSize} />
          <Text ml={3} fontSize={currentFontSize}>
            {label}
          </Text>
          {children && (
            <Icon
              as={isOpen ? FaChevronUp : FaChevronDown}
              color={textColor}
              boxSize={sidebarIconSize}
              ml="auto"
            />
          )}
        </MotionBox>
      </Link>
      {children && (
        <Collapse in={isOpen} animateOpacity>
          <VStack pl={4} align="stretch">
            {children.map((childLink, idx) => (
              <SidebarLink
                key={idx}
                icon={childLink.icon}
                label={childLink.label || childLink.page}
                to={childLink.to || childLink.slug}
                hoverBg={hoverBg}
                colorMode={colorMode}
                size="sm"
              />
            ))}
          </VStack>
        </Collapse>
      )}
    </>
  );
};

const SidebarContent = ({
  onClose,
  colorMode,
  menuItems,
  role,
  glassEffect,
  selectedLink,
  onLinkClick,
}) => {
  const sidebarHeadingSize = useBreakpointValue({ base: "lg", md: "xl" });
  const marginingMobile = useBreakpointValue({ base: 4, md: 1 });

  const filteredMenuItems = menuItems.filter((item) => {
    const access = item.dashboardAccess.find(
      (access) => access.role === convertRoleToApi(role)
    );
    //console.log("children", item.children);
    return access?.R;
  });

  return (
    <VStack align="stretch" spacing={1} m={marginingMobile}>
      <Text
        fontSize={sidebarHeadingSize}
        fontWeight="bold"
        textAlign="left"
        ml={1}
        mb={3}
      >
        Starntop {role}
      </Text>
      {role === "admin"
        ? sidebarLinks.map((link, index) => (
            <SidebarLink
              glassEffect={glassEffect}
              key={index}
              colorMode={colorMode}
              icon={link.icon}
              label={link.label}
              to={link.to}
              onClose={onClose}
              onClick={() => onLinkClick(link.to)}
              children={link.children}
            />
          ))
        : filteredMenuItems.map((link, index) => (
            <SidebarLink
              glassEffect={glassEffect}
              key={index}
              colorMode={colorMode}
              icon={link.icon}
              label={link.page}
              to={link.slug}
              selected={link.slug === selectedLink}
              onClick={() => onLinkClick(link.slug)}
              onClose={onClose}
              children={link.children}
            />
          ))}
      {role === "staff" &&
        sidebarLinksForStaff.map((link, index) => (
          <SidebarLink
            glassEffect={glassEffect}
            key={index}
            colorMode={colorMode}
            icon={link.icon}
            label={link.label}
            to={link.to}
            onClose={onClose}
            onClick={() => onLinkClick(link.to)}
            children={link.children}
          />
        ))}
      <Box mt="auto" mb={4}>
        <SidebarLink
          glassEffect={glassEffect}
          colorMode={colorMode}
          icon="FaSignOutAlt"
          label="Logout"
          to="/logout"
          onClose={onClose}
        />
      </Box>
    </VStack>
  );
};

const Sidebar = ({ role, setDynamicRoutes }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sidebarWidth = "250px";
  const { colorMode } = useColorMode();
  const [menuItems, setMenuItems] = useState([]);
  const location = useLocation();

  const borderColor = useColorModeValue("gray.300", "gray.600");

  const [selectedLink, setSelectedLink] = useState(location.pathname);

  const sidebarStyles = {
    bg: colorMode === "dark" ? "gray.800" : "white",
    backdropFilter: colorMode === "dark" ? "blur(10px)" : "none",
    boxShadow: colorMode === "dark" ? "0 4px 6px rgba(0, 0, 0, 0.3)" : "sm",
    color: colorMode === "dark" ? "white" : "gray.800",
  };

  const glassEffect = {
    bg: colorMode === "dark" ? "rgba(255, 255, 255, 0.05)" : "white",
    backdropFilter: colorMode === "dark" ? "blur(10px)" : "none",
    boxShadow: colorMode === "dark" ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "sm",
  };

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const endpoint = role === "admin" ? "/dashboard" : "/dashboard/menu";
        const response = await API.get(endpoint);
        //console.log("role: ", role, "endpoint: ", endpoint);
        //console.log("response", response.data);

        if (response.data.success) {
          let filteredItems = response.data.data;

          // Filter items based on the role
          if (role === "manager") {
            filteredItems = filteredItems.filter((item) =>
              [
                "dashboard",
                "reports",
                "staff",
                "analytics",
                "items",
                "customizations",
              ].includes(item.page.toLowerCase())
            );
          } else if (role === "merchant") {
            //console.log("Filtered items before applying filter:", filteredItems);
            filteredItems = filteredItems.filter((item) => {
              const normalizedPage = item.page.toLowerCase();
              //console.log("Filtering item:", normalizedPage); // Add this line to debug
              return [
                "dashboard",
                //"reports",
                "merchant manager",
                //"items",
                //"customizations",
                "orders",
                "analytics",
                "stores",
                "loyalty",
                //"operations",
                "settings",
                "device assignment",
                "active session & devices",
                "action logs",
                "menu",
              ].includes(normalizedPage);
            });
            //console.log("Filtered items after applying filter:", filteredItems);
          }

          setMenuItems(filteredItems);
          setDynamicRoutes(filteredItems);
        }
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };

    fetchMenuItems();
  }, [role, setDynamicRoutes]);

  const handleLinkClick = (slug) => {
    setSelectedLink(slug);
    onClose();
  };

  const displaySidebar = useBreakpointValue({ base: "none", md: "block" });

  return (
    <>
      {/* Hamburger menu button for mobile */}
      <IconButton
        aria-label="Open Menu"
        icon={<HamburgerIcon />}
        onClick={onOpen}
        display={{ base: "inline-flex", md: "none" }}
        position="fixed"
        zIndex="overlay"
        m={4}
        top="0px"
        left="0px"
      />

      {/* Drawer for mobile view */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent
            menuItems={menuItems}
            role={role}
            onClose={onClose}
            colorMode={colorMode}
            onLinkClick={handleLinkClick}
            glassEffect={glassEffect}
            selectedLink={selectedLink}
          />
        </DrawerContent>
      </Drawer>

      {/* Permanent sidebar for larger screens */}
      <Box
        display={displaySidebar}
        as="nav"
        position="fixed"
        left="0"
        top="0"
        h="full"
        w={sidebarWidth}
        bg={glassEffect}
        overflowY="auto"
        p={4}
        transition="width .3s"
        {...sidebarStyles}
      >
        <SidebarContent
          menuItems={menuItems}
          role={role}
          colorMode={colorMode}
          onLinkClick={handleLinkClick}
          glassEffect={glassEffect}
          selectedLink={selectedLink}
        />
        {/* Vertical Divider */}
        <Box
          position="absolute"
          right="0"
          top="0"
          bottom="0"
          width="0.5px"
          bg={borderColor}
        />
      </Box>
    </>
  );
};

export default Sidebar;
