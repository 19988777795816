import {useEffect} from "react";
import axios from "axios";

export const Logout = () => {
    useEffect(() => {
        
        (async () => {
            try {
                const role = localStorage.getItem("role")
                const {data} = await axios.post('logout/', {
                    refresh_token: localStorage.getItem('refresh_token')
                }, {
                    headers: {'Content-Type': 'application/json'},
                    withCredentials: true
                });

                // localStorage.clear();
                axios.defaults.headers.common['Authorization'] = null;
                if(role === "admin"){
                    localStorage.clear();
                    window.location.href = 'https://admin.kiwikrave.com/login';
                }
                else if(role === "merchant"){
                    localStorage.clear();
                    window.location.href ="https://merchant.kiwikrave.com/login"
                }
                // window.location.href = '/login';
            } catch (e) {
                console.log('logout not working', e);
            }
        })();
    }, []);

    return (
        <div></div>
    );
}
