// Customers.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Icon,
  Flex,
  Spinner,
  VStack,
  HStack,
  useColorModeValue,
  Heading,
  IconButton,
  Grid,
  GridItem,
  Avatar,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Collapse,
  Button,
} from "@chakra-ui/react";
import {
  FiArrowLeft,
  FiUsers,
  FiTrendingUp,
  FiUserCheck,
  FiUserPlus,
  FiDollarSign,
  FiRefreshCcw,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import API from "../../context/API";
import { Doughnut, Line } from "react-chartjs-2";
import {
  Chart,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";

import BarLineChart from "./BarLineChart";
import ChartSelection from "./ChartSelection";
import DateSelector from "./DateSelector";
import DateRangePicker from "./DateRangePicker";
import moment from "moment";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import CustomersDropDown from "../Loyalty/CutomersDropDown";

Chart.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

/**
 * InfoTile Component
 * Displays individual metrics such as Total Customers, New Customers, etc.
 *
 * @param {object} props - Component props.
 * @param {string} props.title - Title of the metric.
 * @param {string} props.subTitle - Subtitle or description of the metric.
 * @param {string|number} props.value - Value of the metric.
 * @param {React.Component} props.icon - Icon representing the metric.
 * @param {string} props.iconColor - Color of the icon.
 * @param {object} props.chartData - Data for the chart.
 * @returns JSX Element
 */
const InfoTile = ({ title, subTitle, value, icon, iconColor, chartData }) => {
  // Define color constants
  const tileBg = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.300");

  // Chart configuration
  const data = {
    labels: chartData ? chartData.labels : [],
    datasets: [
      {
        data: chartData ? chartData.data : [],
        backgroundColor: chartData ? chartData.backgroundColor : [],
        hoverBackgroundColor: chartData ? chartData.hoverBackgroundColor : [],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "65%", // Adjust cutout percentage for doughnut thickness
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: true, // Enable tooltips
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleColor: "#fff",
        bodyColor: "#fff",
        bodyFont: {
          size: 14,
        },
        padding: 10,
        cornerRadius: 4,
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.formattedValue || "";
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((context.raw / total) * 100).toFixed(2);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0, // Remove segment borders
      },
    },
  };

  return (
    <Box
      bg={tileBg}
      borderRadius="md"
      p={4}
      w="100%"
      h="300px" // Fixed height for consistent tile sizes
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {/* Top Section: Icon and Text */}
      <Box>
        <HStack align="start" mb={2}>
          <Icon as={icon} w={8} h={8} color={iconColor} />
          <Box>
            <Text fontSize="lg" fontWeight="bold" color={textColor}>
              {title}
            </Text>
            <Text fontSize="sm" color={subTextColor} noOfLines={3}>
              {subTitle}
            </Text>
          </Box>
        </HStack>
        {/* Remove the value from here if not needed */}
      </Box>
      {/* Bottom Section: Chart with Value Inside */}
      {chartData && (
        <Box
          position="relative"
          width="100%"
          height="150px" // Adjust height as needed
          mt={2}
        >
          <Doughnut data={data} options={options} />
          {/* Value Text Positioned at the Center of the Chart */}
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            textAlign="center"
            pointerEvents="none" // Allow hover events to pass through to the chart
          >
            <Text fontSize="2xl" fontWeight="bold" color={textColor}>
              {value}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

/**
 * CustomersRankingGrid Component
 * Displays the top 5 customers in a grid layout.
 *
 * @param {object} props - Component props.
 * @param {Array} props.data - Array of top customer objects.
 * @returns JSX Element
 */
const CustomersRankingGrid = ({ data }) => {
  // Define color constants
  const textColor = useColorModeValue("gray.800", "white");
  const headerBg = useColorModeValue("gray.100", "gray.700");
  const rowBorderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4} p={2} borderRadius="md">
      {/* Header Row */}
      <GridItem colSpan={1}>
        <Text fontWeight="bold" color={textColor}>
          Customer
        </Text>
      </GridItem>
      <GridItem>
        <Text fontWeight="bold" color={textColor} textAlign="right">
          Visits
        </Text>
      </GridItem>
      <GridItem>
        <Text fontWeight="bold" color={textColor} textAlign="right">
          Recent Visit
        </Text>
      </GridItem>
      <GridItem>
        <Text fontWeight="bold" color={textColor} textAlign="right">
          Total Spend ($)
        </Text>
      </GridItem>

      {/* Data Rows */}
      {data?.map((customer, index) => (
        <React.Fragment key={customer.id}>
          {/* Customer Name with Avatar */}
          <GridItem>
            <HStack spacing={3}>
              <Avatar name={customer.name} size="sm" bg={rowBorderColor} />
              <Text color={textColor}>{customer.name}</Text>
            </HStack>
          </GridItem>
          {/* Visits */}
          <GridItem>
            <Text color={textColor} textAlign="right">
              {customer.visits}
            </Text>
          </GridItem>
          <GridItem>
            <Text color={textColor} textAlign="right">
              {formatDateAndTime(customer.recentVisit).formattedDate +
                "  " +
                formatDateAndTime(customer.recentVisit).formattedTime}
            </Text>
          </GridItem>
          {/* Total Spend */}
          <GridItem>
            <Text color={textColor} textAlign="right">
              ${customer.totalSpent.toFixed(2)}
            </Text>
          </GridItem>
        </React.Fragment>
      ))}
    </Grid>
  );
};

/**
 * RecentCustomersTable Component
 * Displays a table of recent customer visits.
 *
 * @param {object} props - Component props.
 * @param {Array} props.data - Array of recent customer visit objects.
 * @returns JSX Element
 */

const formatDateAndTime = (isoString) => {
  const date = new Date(isoString);
  const formattedDate = date.toLocaleDateString("en-GB");
  const formattedTime = date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return { formattedDate, formattedTime };
};
const RecentCustomersTable = ({ data }) => {
  // Define color constants
  const textColor = useColorModeValue("gray.800", "white");
  const gridHeaderBg = useColorModeValue("gray.100", "gray.700");
  const gridRowBorderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box w="100%" mt={4}>
      <Text fontSize="lg" fontWeight="bold" mb={2} color={textColor}>
        Recent Customer Visits
      </Text>
      <Grid
        templateColumns="repeat(4, 1fr)"
        gap={4}
        p={2}
        borderRadius="md"
        border="1px"
        borderColor={useColorModeValue("gray.200", "gray.600")}
      >
        {/* Header Row */}
        <GridItem colSpan={1}>
          <Text fontWeight="bold" color={textColor}>
            Customer
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" color={textColor} textAlign="right">
            Date
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" color={textColor} textAlign="right">
            Time
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" color={textColor} textAlign="right">
            Amount Spent ($)
          </Text>
        </GridItem>

        {/* Data Rows */}
        {data?.map((visit) => (
          <React.Fragment key={visit.id}>
            {/* Customer Name with Avatar */}
            <GridItem>
              <HStack spacing={3}>
                <Avatar name={visit.name} size="sm" bg={gridRowBorderColor} />
                <Text color={textColor}>{visit.name}</Text>
              </HStack>
            </GridItem>
            {/* Date */}
            <GridItem>
              <Text color={textColor} textAlign="right">
                {formatDateAndTime(visit.date).formattedDate}
              </Text>
            </GridItem>
            {/* Time */}
            <GridItem>
              <Text color={textColor} textAlign="right">
                {formatDateAndTime(visit.date).formattedTime}
              </Text>
            </GridItem>
            {/* Amount Spent */}
            <GridItem>
              <Text color={textColor} textAlign="right">
                ${visit.amount.toLocaleString()}
              </Text>
            </GridItem>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

const CustomersOrderTable = ({ customers }) => {
  const [customerId, setCustomerId] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const getPhoneBlurred = (phone) => {
    if (phone.length > 4) {
      return phone.slice(0, -4);
    } else {
      return phone.slice(0, -2);
    }
  };
  const getPhoneUnBlurred = (phone) => {
    if (phone.length > 4) {
      return phone.slice(-4);
    } else {
      return phone.slice(-2);
    }
  };
  return (
    <Box overflowX="auto" borderRadius="md" mt="4">
      <Heading size="md">Customer Orders</Heading>
      <Table variant="simple" minWidth="600px">
        <Thead>
          <Tr>
            <Th>Customer Name</Th>
            <Th>Phone</Th>
            <Th>Total Spent</Th>
            <Th>Recent Visit</Th>
            <Th>Order Count</Th>
            <Th>Average Spent</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {customers?.map((customer) => (
            <React.Fragment key={customer.userName}>
              <Tr>
                <Td>{customer.userName}</Td>
                <Td>
                  <Box display={"flex"}>
                    <Text
                      display={"flex"}
                      as="span"
                      filter="blur(3px)"
                      whiteSpace={"nowrap"}
                    >
                      {getPhoneBlurred(customer.userPhone)}
                    </Text>
                    {getPhoneUnBlurred(customer.userPhone)}
                  </Box>
                </Td>
                <Td>
                  $
                  {customer?.stats?.totalSpent != null &&
                  !isNaN(customer.stats?.totalSpent)
                    ? parseFloat(customer.stats?.totalSpent).toFixed(2)
                    : "0.00"}
                </Td>
                <Td>
                  {formatDateAndTime(customer.stats?.recentVisit).formattedDate}{" "}
                  {""}{" "}
                  {formatDateAndTime(customer.stats?.recentVisit).formattedTime}
                </Td>
                <Td>{customer.stats?.orderCount}</Td>
                <Td>
                  $
                  {customer?.stats?.averageSpent != null &&
                  !isNaN(customer?.stats?.averageSpent)
                    ? parseFloat(customer.stats?.averageSpent).toFixed(2)
                    : "0.00"}
                </Td>
                <Td>
                  <Button
                    variant="link"
                    onClick={() => {
                      if (
                        customer.userPhone + customer.userName ===
                        customerId
                      ) {
                        setCustomerId(null);
                      } else {
                        setCustomerId(customer.userPhone + customer.userName);
                      }
                    }}
                    rightIcon={
                      customer.userPhone + customer.userName === customerId ? (
                        <ChevronUpIcon />
                      ) : (
                        <ChevronDownIcon />
                      )
                    }
                  >
                    {customer.userPhone + customer.userName === customerId
                      ? "Hide Details"
                      : "View Details"}
                  </Button>
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={6} p="0" backgroundColor={"white"}>
                  <Collapse
                    in={customer.userPhone + customer.userName === customerId}
                  >
                    <Table variant="simple" minWidth="600px" m={8}>
                      <Thead>
                        <Tr>
                          <Heading size="md" mb="4">
                            Orders
                          </Heading>
                        </Tr>
                        <Tr>
                          <Th>Order Date & Time</Th>
                          <Th>Total Amount</Th>
                          <Th>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {customer?.orders.map((order) => (
                          <React.Fragment key={order.id}>
                            <Tr>
                              <Td>
                                {formatDateAndTime(order.date).formattedDate}
                                {""}{" "}
                                {formatDateAndTime(order.date).formattedTime}
                              </Td>
                              <Td>
                                $
                                {order?.totalAmount != null &&
                                !isNaN(order.totalAmount)
                                  ? parseFloat(order.totalAmount).toFixed(2)
                                  : "0.00"}
                              </Td>
                              <Td>
                                <Button
                                  variant="link"
                                  onClick={() => {
                                    if (order.id === orderId) {
                                      setOrderId(null);
                                    } else {
                                      setOrderId(order.id);
                                    }
                                  }}
                                  rightIcon={
                                    order.id === orderId ? (
                                      <ChevronUpIcon />
                                    ) : (
                                      <ChevronDownIcon />
                                    )
                                  }
                                >
                                  {order.id === orderId
                                    ? "Hide Details"
                                    : "View Details"}
                                </Button>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td colSpan={12} p="0">
                                <Collapse in={order.id === orderId}>
                                  <Box p="4">
                                    <Heading size="md" mb="4">
                                      Order Items
                                    </Heading>
                                    <HStack
                                      align="start"
                                      spacing="4"
                                      flexWrap={"wrap"}
                                    >
                                      {order?.items.map((item) => (
                                        <Box
                                          borderColor={"black"}
                                          borderWidth={"1px"}
                                          borderRadius={"md"}
                                          p={4}
                                        >
                                          <Text>Name: {item.name}</Text>
                                          <Text>Quantity: {item.quantity}</Text>
                                          <Text>Price: {item.price}</Text>
                                        </Box>
                                      ))}
                                    </HStack>
                                  </Box>
                                </Collapse>
                              </Td>
                            </Tr>
                          </React.Fragment>
                        ))}
                      </Tbody>
                    </Table>
                  </Collapse>
                </Td>
              </Tr>
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

/**
 * Customers Component
 * Displays overview and analytics of customers visiting the restaurant.
 *
 * @returns JSX Element
 */
const Customers = () => {
  const [customerData, setCustomerData] = useState(null);
  const [customerOrderData, setCustomerOrderData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isFilter, setIsFilter] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: moment("2000-01-01"), // Start date is 1st January 2000
    endDate: moment(), // End date is the current date
  });
  const [chartType, setChartType] = useState("line");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [merchant, setMerchant] = useState(null);
  const [customerDataFilter, setCustomerDataFilter] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  // Define color constants using useColorModeValue
  const textColor = useColorModeValue("gray.800", "white");
  const boxBgColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const iconColor = useColorModeValue("black", "lightgrey"); // Black for light mode, light grey for dark mode
  const chartColor1 = useColorModeValue("#666666", "#c2c2c2");
  const chartColor2 = useColorModeValue("#2e2e2e", "#7d7d7d");
  const chartColorHover1 = useColorModeValue("#c2c2c2", "#e0e0e0");
  const chartColorHover2 = useColorModeValue("#e0e0e0", "#e0e0e0");
  const chartbg = useColorModeValue("gray.100", "gray.700");
  const fetchAnalyticsData = async () => {
    try {
      setIsLoading(true);
      const params1 = {
        filter: selectedFilter,
        merchantId:merchant?.id
      };
      const params2 = {
        startDate: selectedDateRange.startDate?.format("YYYY-MM-DD"),
        endDate: selectedDateRange.endDate?.format("YYYY-MM-DD"),
        merchantId:merchant?.id
      };
      const token = localStorage.getItem("access_token");
      const res = await API.get("/analytics/customer-sales", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: isFilter ? params1 : params2,
      });
      const customerData = res.data.data;
      console.log("custom data",customerData)
      setCustomerData({
        metrics: {
          totalCustomers: customerData.totalCustomers,
          newCustomers: customerData.newCustomers,
          repeatCustomers: customerData.repeatCustomers,
          averageSpend: customerData.averageSpend || 0,
          averageVisits: customerData.averageVisits || 0,
        },
        topCustomers: customerData.topCustomers,
        recentVisits: customerData.recentVisits,
      });
      setCustomerDataFilter(customerData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };

  const fetchCustomersOrder = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const res = await API.get("/analytics/customers-with-orders", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomerOrderData(res.data.data.customers);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    fetchCustomersOrder();
    fetchAnalyticsData();
  }, [selectedDateRange, selectedFilter,merchant]);
  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await API.get("/analytics/customer-sales", {
          headers,
        });
        const customerData = response.data.data;
        setCustomerData({
          metrics: {
            totalCustomers: customerData.totalCustomers,
            newCustomers: customerData.newCustomers,
            repeatCustomers: customerData.repeatCustomers,
            averageSpend: customerData.averageSpend || 0,
            averageVisits: customerData.averageVisits || 0,
          },
          topCustomers: customerData.topCustomers,
          recentVisits: customerData.recentVisits,
        });
      } catch (error) {
        console.error("Error fetching customer data:", error);
        setCustomerData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, []);

  const handleSelection = (selection) => {
    setSelectedFilter(selection);
    setIsFilter(true);
  };

  return (
    <Box p={5} color={textColor}>
      {loading ? (
        <Flex justify="center" align="center" minH="80vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <VStack spacing={6} align="stretch">
            <HStack spacing={2} display={"flex"} justifyContent={"space-between"} width={"100%"}>
              <HStack spacing={2}>
                <IconButton
                  icon={<FiArrowLeft />}
                  variant="ghost"
                  color={iconColor}
                  onClick={() => navigate(-1)}
                  aria-label="Go Back"
                  mr={4}
                  size="lg"
                />
                <Icon as={FiUsers} w={6} h={6} color={iconColor} />
                <Heading size="lg">Customers</Heading>
              </HStack>
              {user.role === "admin" && (
                <HStack>
                  <Text>By Merchants</Text>
                  <CustomersDropDown
                    onOptionSelect={(merch) => setMerchant(merch)}
                  />
                </HStack>
              )}
            </HStack>

          {/* Overview Tiles */}
          <Grid
            templateColumns={{
              base: "1fr",
              sm: "repeat(auto-fit, minmax(200px, 1fr))",
            }}
            gap={6}
          >
            <InfoTile
              title="Total Customers"
              subTitle="Total number of customers"
              value={customerData?.metrics?.totalCustomers}
              icon={FiUsers}
              chartData={{
                labels: ["Total Customers"],
                data: [1],
                backgroundColor: [chartColor1],
                hoverBackgroundColor: [chartColorHover1],
              }}
            />
            <InfoTile
              title="New Customers"
              subTitle="Customers who visited for the first time"
              value={customerData?.metrics.newCustomers}
              icon={FiUserPlus}
              chartData={{
                labels: ["New", "Others"],
                data: [
                  customerData?.metrics?.newCustomers,
                  customerData?.metrics?.totalCustomers -
                    customerData?.metrics?.newCustomers,
                ],
                backgroundColor: [chartColor1, chartColor2], // Blue and Gray
                hoverBackgroundColor: [chartColorHover1, chartColorHover2], // Light Blue and Darker Gray
              }}
            />
            <InfoTile
              title="Repeat Customers"
              subTitle="Customers who have visited before"
              value={customerData?.metrics?.repeatCustomers}
              icon={FiUserCheck}
              chartData={{
                labels: ["New", "Others"],
                data: [
                  customerData?.metrics?.newCustomers,
                  customerData?.metrics?.totalCustomers -
                    customerData?.metrics?.newCustomers,
                ],
                backgroundColor: [chartColor1, chartColor2], // Blue and Gray
                hoverBackgroundColor: [chartColorHover1, chartColorHover2], // Light Blue and Darker Gray
              }}
            />
            <InfoTile
              title="Average Spend per Customer"
              subTitle="Average amount spent per customer"
              value={`$${customerData?.metrics.averageSpend.toFixed(2)}`}
              icon={FiTrendingUp}
              chartData={{
                labels: ["Average", "Others"],
                data: [customerData?.metrics?.averageSpend],
                backgroundColor: [chartColor1, chartColor2], // Orange and Gray
                hoverBackgroundColor: [chartColorHover1, chartColorHover2], // Light Orange and Darker Gray
              }}
            />
            <InfoTile
              title="Average Visits per Customer"
              subTitle="Average number of visits per customer"
              value={customerData?.metrics?.averageVisits}
              icon={FiTrendingUp}
              chartData={{
                labels: ["Average", "Others"],
                data: [parseInt(customerData?.metrics?.averageVisits), 10],
                backgroundColor: [chartColor1, chartColor2], // Pink and Gray
                hoverBackgroundColor: [chartColorHover1, chartColorHover2], // Light Pink and Darker Gray
              }}
            />
          </Grid>

          {/* Top Customers Ranking Grid */}
          <Box p={4} borderRadius="md" border="1px" borderColor={borderColor}>
            <Heading size="md" mb={4}>
              Top 5 Customers
            </Heading>
            <CustomersRankingGrid data={customerData?.topCustomers} />
          </Box>

          {/* Customer Trends Line Chart */}
          <Box borderRadius="md" width={"full"} bg={chartbg} boxShadow="md">
            <Heading size="md" mx={5} mt={4}>
              Customer Visits Over Time
            </Heading>
            <Box height="450px" width={"full"}>
              <VStack>
                <HStack
                  padding={4}
                  width={"100%"}
                  justifyContent={"flex-start"}
                  wrap={"wrap"}
                >
                  <DateRangePicker
                    onDateRangeChange={(range) => {
                      setIsFilter(false);
                      setSelectedDateRange(range);
                    }}
                  />
                  <DateSelector onDateSelect={handleSelection} />
                  <ChartSelection onChange={(type) => setChartType(type)} />
                </HStack>
                <HStack
                  paddingX={4}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={5}
                  wrap={"wrap"}
                >
                  <Text display={"flex"} alignItems={"center"}>
                    <Icon
                      as={FiDollarSign}
                      w={5}
                      h={5}
                      color={"green.500"}
                      marginRight={"4px"}
                    />
                    Average Spend :
                    <Text as={"span"}>
                      {customerDataFilter?.averageSpend?.toFixed(2)}
                    </Text>
                  </Text>
                  <Text display={"flex"} alignItems={"center"}>
                    <Icon
                      as={FiTrendingUp}
                      w={5}
                      h={5}
                      color={"blue.500"}
                      marginRight={"4px"}
                    />
                    Average Visits :
                    <Text as={"span"}>{customerDataFilter?.averageVisits}</Text>
                  </Text>
                  <Text display={"flex"} alignItems={"center"}>
                    <Icon
                      as={FiRefreshCcw}
                      w={5}
                      h={5}
                      color={"orange.500"}
                      marginRight={"4px"}
                    />
                    Repeat Customers :
                    <Text as={"span"}>
                      {customerDataFilter?.repeatCustomers}
                    </Text>
                  </Text>
                  <Text display={"flex"} alignItems={"center"}>
                    <Icon
                      as={FiUserPlus}
                      w={5}
                      h={5}
                      color={"purple.500"}
                      marginRight={"4px"}
                    />
                    New Customers :
                    <Text as={"span"}>{customerDataFilter?.newCustomers}</Text>
                  </Text>
                  <Text display={"flex"} alignItems={"center"}>
                    <Icon
                      as={FiUsers}
                      w={5}
                      h={5}
                      color={"purple.500"}
                      marginRight={"4px"}
                    />
                    Total Customers :
                    <Text as={"span"}>
                      {customerDataFilter?.totalCustomers}
                    </Text>
                  </Text>
                </HStack>
                <Box width={"100%"} height={"380px"}>
                  <BarLineChart
                    data={customerDataFilter?.periodStats}
                    chartType={chartType}
                    xKey={"period"}
                    yKey={"numberOfCustomers"}
                    valueKey={"numberOfCustomers"}
                    isLoading={isLoading}
                  />
                </Box>
              </VStack>
            </Box>
          </Box>

          {/* Recent Customers Table */}
          <Box bg={boxBgColor} p={4} borderRadius="md" boxShadow="md">
            <RecentCustomersTable data={customerData?.recentVisits} />
          </Box>

          <Box bg={boxBgColor} p={4} borderRadius="md" boxShadow="md">
            {<CustomersOrderTable customers={customerOrderData} />}
          </Box>
        </VStack>
      )}
    </Box>
  );
};

export default Customers;
