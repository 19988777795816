import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "daterangepicker/daterangepicker.css";
import "daterangepicker";
import API from "../../context/API";

const CustomersDropDown = ({onOptionSelect }) => {
  const [selectedOption, setSelectedOption] = useState("Merchants");
  const [merchants, setMerchants] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const fetchMerchants = async () => {
    try {
      const response = await API.get("/merchant");
      setMerchants(response.data.data || []);
    } catch (error) {
      console.error("Error fetching merchants:", error);
    } finally {
      //   setLoading(false);
    }
 };
 
  useEffect(() => {
    fetchMerchants()
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener to detect clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option.username);
    setIsDropdownOpen(false);

      // Define date ranges based on selection
      const selectedRange = getPredefinedDateRange(option);
      // Pass selected range back to parent
      onOptionSelect(selectedRange);

  };

  const getPredefinedDateRange = (value) => {
    return value
  };

  // Initialize the custom date picker
  useEffect(() => {
    
  }, [ onOptionSelect]);
  
  const listbg = useColorModeValue("blue.50", "gray.600");
  const containbg = useColorModeValue("white", "gray.700");

  return (
    <Box position="relative" ref={dropdownRef}>
      <Button
        onClick={() => setIsDropdownOpen((prev) => !prev)}
        rightIcon={isDropdownOpen ? <FaChevronUp color="#718096"/> : <FaChevronDown color="#718096"/>}
        variant="outline"
        width="145px"
        color="gray.800"
        fontWeight="medium"
        px={4}
        cursor="pointer"
        borderRadius="md"
        boxShadow="sm"
      >
        <Text width={"100%"} color={"gray.500"}>{selectedOption}</Text>
      </Button>
      {isDropdownOpen && (
        <VStack
          position="absolute"
          top="100%"
          left={0}
          width="155px"
          maxheight={"300px"}
          bg={containbg}
          boxShadow="md"
          borderRadius="md"
          mt={2}
          spacing={0}
          zIndex={10}
          overflow={"scroll"}
        >
          {merchants.map((option) => (
            <Box
              key={option.id}
              width="full"
              py={2}
              px={4}
              textAlign="left"
              cursor="pointer"
              bg={selectedOption === option.username ? listbg : "transparent"}
              color={selectedOption === option.username ? "blue.500" : "inherit"}
              fontWeight={selectedOption === option.username ? "bold" : "normal"}
              _hover={{ bg: listbg }}
              onClick={() => handleOptionClick(option)}
            >
              {option.username}
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default CustomersDropDown;
