import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import AppRoutes from "./routes";
import { Box, ChakraProvider, ColorModeScript, Spinner } from "@chakra-ui/react";
import Layout from "./theme/Layout/Layout";
import theme from "./theme/theme";
import { ApiProvider } from "./context/ApiContext";
import LoginPage from "./components/LoginPage";
import { Logout } from "./components/logout";
import { LoadingProvider } from "./context/LoadingContext";
import "./interceptors/axios";
import OtpPhoneInput from "./components/OTPInput";
import API from "./context/API"; // Your API service

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [role, setRole] = useState("");
  const [dynamicRoutes, setDynamicRoutes] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for token validation
  const hostname = window.location.hostname;
  useEffect(() => {
    if (window.location.protocol === "https:" && hostname === "kiwikrave.com") {
      // Redirect to the merchant subdomain
      window.location.href = "https://merchant.kiwikrave.com";
  }
    const validateAccessToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("access_token");
      const role1 = urlParams.get("role");

      if (token) {
        // Store token in local storage
        localStorage.setItem("access_token", token);

        try {
          // Make API call to validate token and fetch user data
          let data = null 
          if(role1 === "merchant"){
            const res = await API.get("merchant/profile", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            data = res.data.data
          }

          if (role1 === "admin"){
            const res = await API.get("admin/profile", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            data = res.data.data
          }
          // Set user and role in local storage
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.setItem("role", data.role);
          window.location.href = "/dashboard"
          // Update state
          setIsLoggedIn(true);
          setRole(data.role);
        } catch (error) {
          console.error("Token validation failed:", error);
          // Clear storage if validation fails
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          localStorage.removeItem("role");
          setIsLoggedIn(false);
        }
      } else {
        // Check local storage for token and role if no token in URL
        const storedToken = localStorage.getItem("access_token");
        const storedRole = localStorage.getItem("role");

        if (storedToken && storedRole) {
          setIsLoggedIn(true);
          setRole(storedRole);
        } else {
          setIsLoggedIn(false);
        }
      }

      setLoading(false); // End loading state after validation
    };

    validateAccessToken();
  }, []);

  if (loading) {
    // Show a loading spinner while validating token
    return (
      <Box textAlign="center" mt="4">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ApiProvider>
        <LoadingProvider>
          <Router>
            <Routes>
              <Route
                path="/otpverify"
                element={
                  <OtpPhoneInput
                    setIsLoggedIn={setIsLoggedIn}
                    otp={loginData?.otp}
                    phone={loginData?.phone}
                    role={role}
                  />
                }
              />
              <Route
                path="/login"
                element={
                  isLoggedIn ? (
                    <Navigate replace to="/dashboard" />
                  ) : (
                    <LoginPage
                      isLoggedIn={isLoggedIn}
                      setIsLoggedIn={setIsLoggedIn}
                      setRole={setRole}
                      setLoginData={setLoginData}
                    />
                  )
                }
              />
              <Route
                path="/*"
                element={
                  isLoggedIn ? (
                    <Layout role={role} setDynamicRoutes={setDynamicRoutes}>
                      <AppRoutes dynamicRoutes={dynamicRoutes} role={role} />
                    </Layout>
                  ) : (
                    <Navigate replace to="/login" />
                  )
                }
              />
              <Route path="/logout" element={<Logout />} />
            </Routes>
          </Router>
        </LoadingProvider>
      </ApiProvider>
    </ChakraProvider>
  );
}

export default App;
