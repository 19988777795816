// CategorySales.js
import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Text,
  Icon,
  Flex,
  Spinner,
  VStack,
  HStack,
  SimpleGrid,
  useColorModeValue,
  Heading,
  IconButton,
  Image,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";
import { FiTrendingUp, FiTrendingDown, FiArrowLeft } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Import Swiper modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import API, { getMediaURL } from "../../context/API";
import DateRangePicker from "./DateRangePicker";
import DateSelector from "./DateSelector";
import ChartSelection from "./ChartSelection";
import BarLineChart from "./BarLineChart";
import moment from "moment";
import KeyMetricsSelector from "./KeyMetricSelector";
import CustomersDropDown from "../Loyalty/CutomersDropDown";

// Reusable SalesSummary Component tailored for Category Sales
const addCommasInPrices = (value) => {
  return parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
const CategorySalesSummary = ({ weeklyChange, borderColor, merchant }) => {
  // Determine the trend direction and color
  const isIncrease = weeklyChange >= 0;
  const trendIcon = isIncrease ? FiTrendingUp : FiTrendingDown;
  const trendColor = isIncrease ? "green.400" : "red.400";

  // Chakra UI color modes
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.500");

  const [selectedFilter, setSelectedFilter] = useState("allTime");
  const [keyMetrics, setKeyMetrics] = useState([]);
  const [totalSales, setTotalSales] = useState("0.00$");
  const [totalCategories, setTotalCategories] = useState(" - ");

  const handleSelection = (selection) => {
    setSelectedFilter(selection);
  };

  const fetchAnalyticsData = async () => {
    try {
      const params1 = {
        filter: selectedFilter,
        merchantId: merchant?.id,
      };
      const token = localStorage.getItem("access_token");
      const res = await API.get("/analytics/category-sales", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params1,
        merchantId: merchant?.id,
      });

      const categoriesData = res.data.data.categories;
      setTotalCategories(categoriesData.length);
      const totalRevenue = categoriesData.reduce(
        (sum, category) => sum + parseFloat(category.totalRevenue),
        0
      );
      setTotalSales(totalRevenue);

      // Calculate average revenue per category
      const averageRevenue = totalRevenue / categoriesData.length || 0;

      // Find best category by revenue
      const bestCategoryByRevenue = categoriesData.reduce((prev, current) =>
        parseFloat(current.totalRevenue) > parseFloat(prev.totalRevenue)
          ? current
          : prev
      );

      // Find best category by units sold
      const bestCategoryByUnitsSold = categoriesData.reduce((prev, current) =>
        current.totalItemsSold > prev.totalItemsSold ? current : prev
      );

      // Set key metrics
      setKeyMetrics([
        {
          id: "totalSales",
          title: "Total Sales",
          subTitle: "Overall revenue from all categories",
          value: `$${addCommasInPrices(totalRevenue.toFixed(2))}`,
          icon: FiTrendingUp,
          color: "green.500",
        },
        {
          id: "bestCategoryRevenue",
          title: "Best Category by Revenue",
          subTitle: bestCategoryByRevenue.category || "-",
          value: `$${addCommasInPrices(
            parseFloat(bestCategoryByRevenue.totalRevenue).toFixed(2)
          )}`,
          icon: FiTrendingUp,
          color: "blue.500",
        },
        {
          id: "bestCategoryUnits",
          title: "Best Category by Units Sold",
          subTitle: bestCategoryByUnitsSold.category || "-",
          value: `${bestCategoryByUnitsSold.totalItemsSold} Units`,
          icon: FiTrendingUp,
          color: "orange.500",
        },
        {
          id: "averageSales",
          title: "Average Revenue per Category",
          subTitle: "Average sales per category",
          value: `$${addCommasInPrices(averageRevenue.toFixed(2))}`,
          icon: FiTrendingUp,
          color: "purple.500",
        },
      ]);

    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };

  useEffect(() => {
    fetchAnalyticsData();
  }, [selectedFilter,merchant]);
  return (
    <Box
      p={6}
      bg={useColorModeValue("gray.100", "gray.700")}
      borderRadius="md"
      boxShadow="md"
      mb={6}
    >
      <VStack align="start" spacing={0}>
        <HStack gap={2}>
          <Text fontSize="xl" color={textColor}>
            Total Category Sales
          </Text>
          <Box backgroundColor={"white"}>
            <DateSelector onDateSelect={handleSelection} />
          </Box>
        </HStack>

        <Text fontSize="sm" color={subTextColor} mb={"4"}>
          Comprehensive overview of sales across all categories.
        </Text>
        <HStack>
          <Text fontSize="4xl" fontWeight="bold" color={textColor}>
            ${addCommasInPrices(parseFloat(totalSales).toFixed(2))}
          </Text>
          <Flex align="center">
            <Icon as={trendIcon} color={trendColor} w={5} h={5} />
            <Text ml={1} fontSize="md" color={trendColor} fontWeight="semibold">
              {weeklyChange === 0 ? "0%" : `${Math.abs(weeklyChange)}%`}{" "}
              {isIncrease ? "↑" : "↓"} this week
            </Text>
          </Flex>
        </HStack>
        <Text fontSize="md" color={subTextColor}>
          Total Categories: {totalCategories}
        </Text>

        <Box>
          <Text fontSize="2xl" fontWeight="bold" mb={4}>
            Key Metrics
          </Text>
          <SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} spacing={6}>
            {keyMetrics.map((metric) => (
              <Box
                key={metric.id}
                borderWidth="1px"
                borderColor={borderColor}
                borderRadius="md"
                p={4}
              >
                <HStack spacing={3}>
                  <Icon as={metric.icon} w={6} h={6} color={metric.color} />
                  <Text fontWeight="bold">{metric.title}</Text>
                </HStack>
                <Text fontSize="xl" fontWeight="bold" mt={2}>
                  {metric.value}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </VStack>
    </Box>
  );
};

// New Reusable CategorySalesRankingCarousel Component with Ranking Numbers
const CategorySalesRankingCarousel = ({ data, rankingBy, onToggleRanking }) => {
  const subTextColor = useColorModeValue("gray.600", "gray.500");
  const cardBg = useColorModeValue("gray.100", "gray.700");
  const cardTextColor = useColorModeValue("gray.800", "white");

  // Define gradient colors for rankings
  const getRankGradient = (rank) => {
    switch (rank) {
      case 1:
        return "linear(to-r, yellow.400, yellow.600)";
      case 2:
        return "linear(to-r, gray.300, gray.500)";
      case 3:
        return "linear(to-r, orange.400, orange.600)";
      default:
        return "linear(to-r, gray.400, gray.600)";
    }
  };

  return (
    <Box mt={8}>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Box mr={"4"}>
          <Text fontSize="2xl" fontWeight="bold">
            Category Sales Ranking
          </Text>
          <Text fontSize="sm" color={subTextColor} mb={4}>
            Categories ranked by{" "}
            {rankingBy === "revenue" ? "revenue" : "units sold"}.
          </Text>
        </Box>
        <Button size="sm" onClick={onToggleRanking}>
          Rank by {rankingBy === "revenue" ? "Units Sold" : "Revenue"}
        </Button>
      </Flex>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={20}
        slidesPerView={useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 })}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        style={{ padding: "20px 0" }}
      >
        {data.map((category, index) => (
          <SwiperSlide key={category.id} style={{ padding: "15px" }}>
            <Box
              bg={cardBg}
              borderRadius="lg"
              p={2}
              textAlign="center"
              boxShadow="md"
              height="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {/* Ranking Number */}
              <Text
                fontSize="3xl"
                fontWeight="bold"
                bgGradient={getRankGradient(index + 1)}
                bgClip="text"
                mb={4}
              >
                {index + 1}
              </Text>
              {/* Category Image */}
              <Image
                src={
                  getMediaURL(category.imageUrl) ||
                  "https://via.placeholder.com/100"
                }
                alt={category.category}
                borderRadius="full"
                boxSize="120px"
                objectFit="cover"
                mb={4}
                fallbackSrc="https://via.placeholder.com/100"
              />
              {/* Category Name */}
              <Text
                fontSize="lg"
                fontWeight="bold"
                color={cardTextColor}
                mb={2}
              >
                {category.category || " - "}
              </Text>
              {/* Revenue or Units Sold */}
              {rankingBy === "revenue" ? (
                <Text fontSize="sm" color={subTextColor}>
                  Revenue: $
                  {addCommasInPrices(
                    parseFloat(category.totalRevenue).toFixed(2)
                  )}
                </Text>
              ) : (
                <Text fontSize="sm" color={subTextColor}>
                  Units Sold: {category.totalItemsSold}
                </Text>
              )}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

// Category Sales Detailed View Component
const CategorySales = () => {
  const [categories, setCategories] = useState([]);
  const [loadingSales, setLoadingSales] = useState(true);
  const [weeklyChange, setWeeklyChange] = useState(0);
  const [rankingBy, setRankingBy] = useState("revenue"); // 'revenue' or 'unitsSold'
  const [merchant, setMerchant] = useState(null);
  const [categorySalesData, setCategorySalesData] = useState(null);
  const [keyMetricsFilter, setKeyMetricsFilter] = useState({});
  const [isFilter, setIsFilter] = useState(false);
  const [metricOption, setMetricOption] = useState("totalRevenue");

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: moment("2000-01-01"), // Start date is 1st January 2000
    endDate: moment(), // End date is the current date
  });
  const [chartType, setChartType] = useState("line");

  const [selectedFilter, setSelectedFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate
  const user = JSON.parse(localStorage.getItem("user"));

  // Chakra UI color modes
  const textColor = useColorModeValue("gray.800", "white");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const subTextColor = useColorModeValue("gray.600", "gray.400");
  const chartbg = useColorModeValue("gray.100", "gray.700");
  const handleSelection = (selection) => {
    setIsFilter(true);
    setSelectedFilter(selection);
  };

  const fetchAnalyticsData = async () => {
    try {
      setIsLoading(true);
      const params1 = {
        filter: selectedFilter,
      };
      const params2 = {
        startDate: selectedDateRange.startDate?.format("YYYY-MM-DD"),
        endDate: selectedDateRange.endDate?.format("YYYY-MM-DD"),
      };
      const token = localStorage.getItem("access_token");
      const res = await API.get("/analytics/category-sales", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: isFilter ? params1 : params2,
      });

      const categoriesData = res.data.data.categories;
      const totalRevenue =
        categoriesData?.length > 0
          ? categoriesData.reduce(
              (sum, category) => sum + parseFloat(category.totalRevenue),
              0
            )
          : 0;
      const averageRevenue = totalRevenue / categoriesData.length || 0;

      // Find best category by revenue
      const bestCategoryByRevenue =
        categoriesData?.length > 0
          ? categoriesData.reduce((prev, current) =>
              parseFloat(current.totalRevenue) > parseFloat(prev.totalRevenue)
                ? current
                : prev
            )
          : 0;

      // Find best category by units sold
      const bestCategoryByUnitsSold =
        categoriesData?.length > 0
          ? categoriesData.reduce((prev, current) =>
              current.totalItemsSold > prev.totalItemsSold ? current : prev
            )
          : 0;
      setKeyMetricsFilter({
        totalRevenue: totalRevenue.toFixed(2),
        averageRevenue: averageRevenue.toFixed(2),
        bestCategoryByRevenue: parseFloat(
          bestCategoryByRevenue.totalRevenue
        ).toFixed(2),
        bestCategoryByUnitsSold: bestCategoryByUnitsSold.totalItemsSold,
      });
      console.log("categories data", categoriesData);
      setCategorySalesData(categoriesData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setLoadingSales(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalyticsData();
  }, [selectedFilter, selectedDateRange, isFilter,merchant]);

  useEffect(() => {
    if (categorySalesData) {
      const categoriesData = categorySalesData;
      // Set categories for chart and ranking
      setCategories(categoriesData);
    }
    setLoadingSales(false);
  }, [categorySalesData]);

  // Toggle ranking between revenue and units sold
  const onToggleRanking = () => {
    setRankingBy(rankingBy === "revenue" ? "unitsSold" : "revenue");
  };

  // Get sorted categories for ranking
  const sortedCategories = useMemo(() => {
    const sorted = [...categories];
    if (rankingBy === "revenue") {
      sorted.sort((a, b) => parseFloat(b.revenue) - parseFloat(a.revenue));
    } else {
      sorted.sort((a, b) => b.itemsSold - a.itemsSold);
    }
    return sorted;
  }, [categories, rankingBy]);

  return (
    <Box p={5} color={textColor}>
      {loadingSales ? (
        <Flex justify="center" align="center" minH="100vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <VStack spacing={6} align="stretch">
          {/* Heading with Back Button */}
          <Flex justify="flex-start" align="center">
            <IconButton
              icon={<FiArrowLeft />}
              variant="outline"
              color={textColor}
              borderRadius={"full"}
              onClick={() => navigate(-1)} // Navigate back in history
              _hover={{ bg: "gray.200" }}
              aria-label="Go Back to Analytics Dashboard"
              mr={"4"}
              size="lg"
            />
            <VStack align="start" spacing={1} width={"100%"}>
              <HStack
                display={"flex"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Heading size="xl" fontWeight="bold">
                  Category Sales
                </Heading>
                {user.role === "admin" && (
                  <HStack>
                    <Text>By Merchants</Text>
                    <CustomersDropDown
                      onOptionSelect={(merch) => setMerchant(merch)}
                    />
                  </HStack>
                )}
              </HStack>
              <Text fontSize="md" color={subTextColor}>
                Analyze sales performance by category
              </Text>
            </VStack>
            {/* Placeholder to balance the flex layout */}
            <Box width="75px" />
          </Flex>

          {/* Sales Summary Header */}
          <CategorySalesSummary
            weeklyChange={weeklyChange}
            borderColor={borderColor}
            merchant={merchant}
          />

          <Box
            // p={4}
            borderRadius="md"
            height="450px"
            width={"full"}
            bg={chartbg}
            boxShadow="md"
          >
            <VStack>
              <HStack
                padding={4}
                width={"100%"}
                justifyContent={"flex-start"}
                wrap={"wrap"}
              >
                <DateRangePicker
                  onDateRangeChange={(range) => {
                    setIsFilter(false);
                    setSelectedDateRange(range);
                  }}
                />
                <DateSelector onDateSelect={handleSelection} />
                <KeyMetricsSelector
                  options={[
                    { label: "Revenue", value: "totalRevenue" },
                    { label: "Items Sold", value: "totalItemsSold" },
                  ]}
                  onSelectionChange={(option) => setMetricOption(option)}
                />
                <ChartSelection onChange={(type) => setChartType(type)} />
              </HStack>
              <HStack
                paddingX={4}
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={10}
                wrap={"wrap"}
              >
                <Text display={"flex"} alignItems={"center"}>
                  <Icon
                    as={FiTrendingUp}
                    w={5}
                    h={5}
                    color={"green.500"}
                    marginRight={"4px"}
                  />
                  Total :$
                  <Text as={"span"}>
                    {addCommasInPrices(keyMetricsFilter?.totalRevenue)}
                  </Text>
                </Text>
                <Text display={"flex"} alignItems={"center"}>
                  <Icon
                    as={FiTrendingUp}
                    w={5}
                    h={5}
                    color={"blue.500"}
                    marginRight={"4px"}
                  />
                  Best Category by Revenue :$
                  <Text as={"span"}>
                    {addCommasInPrices(keyMetricsFilter?.bestCategoryByRevenue)}
                  </Text>
                </Text>
                <Text display={"flex"} alignItems={"center"}>
                  <Icon
                    as={FiTrendingUp}
                    w={5}
                    h={5}
                    color={"orange.500"}
                    marginRight={"4px"}
                  />
                  Best Category by Units Sold :
                  <Text as={"span"}>
                    {keyMetricsFilter?.bestCategoryByUnitsSold}
                  </Text>
                </Text>
                <Text display={"flex"} alignItems={"center"}>
                  <Icon
                    as={FiTrendingUp}
                    w={5}
                    h={5}
                    color={"purple.500"}
                    marginRight={"4px"}
                  />
                  Average Revenue per Category :$
                  <Text as={"span"}>
                    {addCommasInPrices(keyMetricsFilter?.averageRevenue)}
                  </Text>
                </Text>
              </HStack>
              <Box width={"100%"} height={"380px"}>
                <BarLineChart
                  data={categorySalesData}
                  chartType={chartType}
                  xKey={"category"}
                  yKey={metricOption}
                  valueKey={metricOption}
                />
              </Box>
            </VStack>
          </Box>

          {/* Key Metrics Tiles */}

          {/* Category Sales Ranking Carousel */}
          <CategorySalesRankingCarousel
            data={sortedCategories}
            rankingBy={rankingBy}
            onToggleRanking={onToggleRanking}
          />
        </VStack>
      )}
    </Box>
  );
};

export default CategorySales;
