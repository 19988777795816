import React, { useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  List,
  ListItem,
  InputGroup,
  Tbody,
  InputRightAddon,
  CloseButton,
  Wrap,
  WrapItem,
  Badge,
} from "@chakra-ui/react";
import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  CheckIcon
} from "@chakra-ui/icons";
import API from "../../context/API";
import SchedulesSeletor from "./Schedules";

const Stores = () => {
  const [stores, setStores] = useState([
    {
      name: "test store",
      address: "31 street 44A",
      id: "2323",
    },
  ]);

  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false); // Separate state for refresh loading
  const [error, setError] = useState(null);
  const [currentStore, setCurrentStore] = useState({
    name: "",
    address: "",
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();
  const categoriesBoxRef = useRef(null);
  const [queryCategories, setQueryCategories] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [showSuggestionsCategories, setShowSuggestionsCategories] =
    useState(false);
  const [categories, setCategories] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedules, setSeletedSchedules] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const handleSearchCategories = (value) => {
    setQueryCategories(value);
    if (value) {
      setFilteredCategories(
        categoryOptions.filter((item) =>
          item.category.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredCategories(categoryOptions);
    }
  };

  const handleClearSearchCategories = () => {
    setQueryCategories("");
    setFilteredCategories(categoryOptions);
    setShowSuggestionsCategories(false);
  };

  const handleOptionSelectCategory = (selectedItem) => {
    if (!categories.some((c) => c.id === selectedItem.id)) {
      const newCategory = {
        ...selectedItem,
        order: categories.length,
      };
      setCategories([...categories, newCategory]);
    }
    setShowSuggestionsCategories(false);
    setQueryCategories("");
  };

  const handleRemoveCategory = (id) => {
    const updatedCategories = categories.filter((c) => c.id !== id);
    setCategories(updatedCategories);
  };

  const fetchCategories = async () => {
    try {
      const response = await API.get("/categories");
      if (response.data.success) {
        setCategoryOptions(response.data.data);
        setFilteredCategories(response.data.data);
      } else {
        toast({
          title: "Failed to fetch categories.",
          description: "Unable to load categories.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast({
        title: "Error fetching categories.",
        description: "Unable to load categories.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchStores();
    fetchCategories();
    fetchSchedules();
  }, []);

  const fetchStores = async () => {
    try {
      const response = await API.get("/stores");
      setStores(response.data.data || []);
      setCategories(response.data.data.categories || []);
    } catch (error) {
      console.error("Error fetching stores:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const fetchSchedules = async () => {
    // setRefreshing(true);
    try {
      const response = await API.get("/schedules");
      setSchedules(response.data);
      
    } catch (error) {
      console.error("Error fetching schedules:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const handleEdit = async (store) => {
    // setLoading(true)
    console.log("store", store, categories);
    setCurrentStore(store);
    setCategoryOptions(store.categories);
    setSeletedSchedules(store.schedules)
    setCategories(store.categories);
    onOpen();
  };

  const handleCreate = () => {
    setCurrentStore({
      name: "",
      address: "",
    });
    setCategories([]);
    setSeletedSchedules([])

    onOpen();
  };

  const handleDelete = (id) => {
    setCurrentStore({ ...currentStore, id });
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await API.delete(`/stores/${currentStore.id}`);
      toast({
        title: "Store deleted.",
        description: "The Store has been deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchStores();
    } catch (error) {
      toast({
        title: "Error deleting Store.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleteDialogOpen(false);
    }
  };
  const handleSubmit = async () => {
    console.log(categories);
    console.log(currentStore);
    let categoryIds = [];
    let scheduleIds = [];
    categories.map((category) => {
      categoryIds.push(category.id);
    });
    selectedSchedules.map((schs)=>{
     scheduleIds.push(schs.id)
    })
    const user = JSON.parse(localStorage.getItem("user"));
    const newStore = {
      name: currentStore.name,
      address:currentStore.address,
      categories: categoryIds,
      schedules:scheduleIds
    };
    try {
      if (currentStore?.id) {
        await API.patch(`/stores/${currentStore.id}`, newStore);

        toast({
          title: "Store Updated.",
          description: "The store has been Updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        await API.post("/stores", newStore);

        toast({
          title: "Store created.",
          description: "The store has been created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      fetchStores();
      onClose();
    } catch (error) {
      console.log("error creating store");
    }
  };
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.200", "gray.600");
  const bgColorAccordian = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.200");

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box my={"4"}>
      <Flex justify="space-between" align="center" marginBottom="4">
        <Box>
          <Heading size={"xl"} letterSpacing={-2}>
            Stores
          </Heading>
          <Text fontSize={"sm"}>
            List of all Stores, you can add new ones, edit and/or delete older
            ones.
          </Text>
        </Box>
        <Button
          display={{ base: "none", md: "flex" }} // Hide on mobile, show on larger screens
          leftIcon={<AddIcon />}
          bg="black"
          color={"white"}
          _hover={{
            color: "black",
            bg: "white",
            borderWidth: "1px",
            borderColor: "gray.300",
          }}
          variant="outline"
          onClick={handleCreate}
        >
          Create New Store
        </Button>
        <IconButton
          display={{ base: "flex", md: "none" }} // Show icon button on mobile
          icon={<AddIcon />}
          size="sm"
          aria-label="Create New Store"
          bg="black"
          color={"white"}
          _hover={{
            color: "black",
            bg: "white",
            borderWidth: "1px",
            borderColor: "gray.300",
          }}
          onClick={handleCreate}
        />
      </Flex>
      <Box p={4} bg={bgColor} borderRadius="md" overflowX="auto">
        <Table variant="simple" minWidth="600px">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Address</Th>
              <Th textAlign={"center"}>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {stores.map((store) => {
              return (
                <Tr>
                  <Td>{store.name}</Td>
                  <Td>{store.address}</Td>
                  <Td>
                    <Flex justifyContent="center">
                      <IconButton
                        icon={<EditIcon />}
                        colorScheme="blue"
                        variant="ghost"
                        aria-label="Edit Store"
                        onClick={() => handleEdit(store)}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        colorScheme="red"
                        variant="ghost"
                        aria-label="Delete Store"
                        onClick={() => handleDelete(store.id)}
                      />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>

      {/* Modal for Creating/Updating Store */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "full", md: "lg", lg: "4xl" }} // Responsive modal sizes
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {currentStore.id ? "Update Store" : "Create Store"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="name" mb="4">
              <FormLabel>Store Name</FormLabel>
              <Input
                placeholder="Enter store name"
                value={currentStore.name}
                onChange={(e) =>
                  setCurrentStore({
                    ...currentStore,
                    name: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl id="Address" mb="4">
              <FormLabel>Address</FormLabel>
              <Input
                placeholder="Enter store address"
                value={currentStore.address}
                onChange={(e) =>
                  setCurrentStore({
                    ...currentStore,
                    address: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl mb="4">
              <Heading fontSize={"md"} mb={2}>
                Categories*
              </Heading>
              <Box position="relative" ref={categoriesBoxRef}>
                <InputGroup>
                  <Input
                    placeholder="Search categories..."
                    value={queryCategories}
                    onChange={(e) => handleSearchCategories(e.target.value)}
                    onFocus={() => setShowSuggestionsCategories(true)}
                    variant="filled"
                    borderRadius="full"
                    mb={4}
                  />
                  {queryCategories && (
                    <InputRightAddon>
                      <CloseButton onClick={handleClearSearchCategories} />
                    </InputRightAddon>
                  )}
                </InputGroup>

                {showSuggestionsCategories && filteredCategories.length > 0 && (
                  <Box
                    position="absolute"
                    bg={bgColor}
                    border="1px solid"
                    borderColor={borderColor}
                    borderRadius="md"
                    mt={2}
                    width="100%"
                    zIndex="1"
                    maxHeight="150px"
                    overflowY="auto"
                  >
                    <List spacing={1}>
                      {filteredCategories.map((category) => (
                        <ListItem
                          key={category.id}
                          p={2}
                          cursor="pointer"
                          _hover={{
                            hoverBg,
                          }}
                          onClick={() => handleOptionSelectCategory(category)}
                        >
                          <Flex justify="space-between" align="center">
                            <Text>{category.category}</Text>
                            {categories.some((c) => c.id === category.id) && (
                              <CheckIcon color="green.500" />
                            )}
                          </Flex>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
              </Box>
            </FormControl>

            <Box
              p={4}
              borderWidth={"1px"}
              borderColor={"#d1d1d1"}
              borderRadius={"lg"}
              mb={4}
            >
              <Text>Selected Categories:</Text>
              {categories.length === 0 ? (
                <Text color="gray.500">No categories selected.</Text>
              ) : (
                <Wrap spacing={2} mt={2}>
                  {categories.map((category) => (
                    <WrapItem key={category.id}>
                      <Badge
                        display="flex"
                        alignItems="center"
                        p={2}
                        borderRadius="full"
                        variant="solid"
                        colorScheme="blue"
                      >
                        {category.category}
                        <IconButton
                          icon={<CloseIcon />}
                          size="xs"
                          ml={2}
                          onClick={() => handleRemoveCategory(category.id)}
                          variant="unstyled"
                          aria-label="Remove Category"
                          _hover={{ color: "red.500" }}
                        />
                      </Badge>
                    </WrapItem>
                  ))}
                </Wrap>
              )}
            </Box>
            {/* schedules */}
            <SchedulesSeletor schedules={schedules} selectedSch={selectedSchedules} onScheduleChange={(sch)=>setSeletedSchedules(sch)}/>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="black"
              color={"white"}
              _hover={{
                color: "black",
                bg: "white",
                borderWidth: "1px",
                borderColor: "gray.300",
              }}
              variant="outline"
              mr={"3"}
              onClick={handleSubmit}
            >
              {currentStore.id ? "Update Store" : "Create Store"}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Alert dialog box for deleting confirmation */}
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Store
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setIsDeleteDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Stores;
